import dayjs from 'dayjs'

import { deserializeDate, deserializeDateTime, setTimezone, formatDate, formatDateTime } from '../utils/dates'

export const dateGetter = ({ value }) => value ? deserializeDate(value) : value
export const dateTimeGetter = ({ value }) => value ? deserializeDateTime(value) : value

export const dateFormatter = ({ value }) => {
  return value ? formatDate(setTimezone(dayjs(value))) : '';
}
export const dateTimeFormatter = ({ value }) => {
  return value ? formatDateTime(setTimezone(dayjs(value))) : '';
}
