import dayjs from 'dayjs';

export function roundToQuarter(floatValue) {
  const decimalPart = floatValue % 1; // Extract the decimal part
  let roundedDecimal;

  if (decimalPart < 0.125) {
    roundedDecimal = 0;
  } else if (decimalPart < 0.375) {
    roundedDecimal = 0.25;
  } else if (decimalPart < 0.625) {
    roundedDecimal = 0.5;
  } else if (decimalPart < 0.875) {
    roundedDecimal = 0.75;
  } else {
    roundedDecimal = 1;
  }

  const roundedValue = Math.floor(floatValue) + roundedDecimal;
  return roundedValue;
}

export const getTotal = (start, end, mealTime) => {
  if (!start || !end) {
    return 0;
  }
  start = dayjs(start);
  end = dayjs(end);
  let total = end.diff(start, 'hour', true);
  if (total <= 0) {
    total += 24;
  }
  total -= Number(mealTime);
  if (isNaN(total)) {
    return 0;
  }
  return roundToQuarter(total);
}

export const getShiftTotal = (activity, shift) => {
  if (shift === '1') {
    return getTotal(activity.start, activity.end, activity.meal_time);
  } else if (shift === '2') {
    return getTotal(activity.start2, activity.end2, activity.meal_time2);
  } else if (shift === '3') {
    return getTotal(activity.start3, activity.end3, activity.meal_time3);
  } else {
    return 0.0
  }
};

export const getShiftRemaining = (activity, allocations, shift) => Math.max(0, allocations.reduce(
  (sum, allocation) => 
    allocation.shift === shift ? sum - allocation.duration : sum,
  getShiftTotal(activity, shift)));

export const getBigTotal = (activities) => {
  let total = 0;
  if (activities) {
    total = activities.reduce((accumulator, activity) => {
      // shift 1
      accumulator += getShiftTotal(activity, '1');
      // shift 2
      accumulator += getShiftTotal(activity, '2');
      // shift 3
      accumulator += getShiftTotal(activity, '3');
      return accumulator;
    }, 0);
  }
  return total;
}

export function getRemainTime(activity, allocations) {
  const total = allocations.reduce((accumulator, allocation) => {
    // Use parseFloat to convert the string to a number
    const duration = parseFloat(allocation.duration);
  
    // Check if the numericValue is a valid number (not NaN)
    if (!isNaN(duration)) {
      return accumulator + duration;
    }
  
    // If it's not a valid number, just return the current accumulator
    return accumulator;
  }, 0);
  // shift 1
  let totalWork = getTotal(activity.start, activity.end, activity.meal_time);
  // shift 2
  totalWork += getTotal(activity.start2, activity.end2, activity.meal_time2);
  // shift 3
  totalWork += getTotal(activity.start3, activity.end3, activity.meal_time3);
  const remain = totalWork - total;
  return remain;
}

export const getTimeAllocationsPerShift = (activity) => {
  return activity.time_allocations.reduce((accumulator, currentValue) => {
    const shift = currentValue.shift;
    const value = currentValue.duration;
    accumulator[shift] += value;
    return accumulator;
  }, {'1': 0.0, '2': 0.0, '3': 0.0});
}

export const timeAllocationErrorCheck = (activity) => {
  const shift1 = getShiftTotal(activity, '1');
  const shift2 = getShiftTotal(activity, '2');
  const shift3 = getShiftTotal(activity, '3');
  const allocationsPerShift = getTimeAllocationsPerShift(activity);
  if (allocationsPerShift['1'] !== shift1) {
    return true;
  }
  if (allocationsPerShift['2'] !== shift2) {
    return true;
  }
  if (allocationsPerShift['3'] !== shift3) {
    return true;
  }
  return false;
};

export const workHoursErrorCheck = (activity) => {
  if ((activity.start && !activity.end) || (!activity.start && activity.end)) {
    return true;
  } else if (!dayjs(activity.start).isValid()) {
    return true
  } else if (!dayjs(activity.end).isValid()) {
    return true
  } else {
    return false;
  }
}
