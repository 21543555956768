//import { useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { getBigTotal } from '../../../utils/compute'
import { useAuthentication } from '../../use/login';

const computeAllocationsDistanceTotal = (allocations) => {
  const total = allocations.reduce((cumulator, allocation) => 
    cumulator + (allocation.distance || 0.0)
  , 0.0);
  return total;
}

const computeActivitiesDistanceTotal = (activities) => {
  const total = activities.reduce((cumulator, activity) => 
    cumulator + computeAllocationsDistanceTotal(activity.time_allocations)
  , 0.0);
  return total;
}

function SimplePaper({editable, report, changed, handleChangeApproved}) {
  const [authentication] = useAuthentication();

  const role = authentication.role;
  const access = (role === 'admin') || (role === 'foreman');
  const approved = report.is_approved;

  const onApprovedChange = (event) => {
    const newApproved = !!event.target.checked; 
    if (newApproved !== approved) {
      handleChangeApproved(newApproved)
        .catch((error) => {
        })
    }
    return false;
  }

  const bigTotal = getBigTotal(report.activities);
  const distanceTotal = computeActivitiesDistanceTotal(report.activities);

  let reg = bigTotal;
  let half = 0;

  if (bigTotal > 40) {
    reg = 40;
    half = bigTotal - reg;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          height: '125px', // Set a fixed height
          width: '50%',    // Set a percentage-based width
          minWidth: '160px', // Set a minimum width if needed
          maxWidth: '500px', // Set a maximum width if needed
        },
      }}
    >
      <Paper elevation={24} sx={{ backgroundColor: '#595959' }}>
        <Box sx={{ padding: 1, color: 'white' }}>
          <Box>
            <Typography fontSize={13}>
              Total d'heures : <b>{bigTotal.toFixed(2)}</b>
            </Typography>
          </Box>
          <Box sx={{ marginTop: '1px' }}>
            <Typography fontSize={13}>
              Régulières : <b>{reg.toFixed(2)}</b>
            </Typography>
          </Box>
          <Box sx={{ marginTop: '1px' }}>
            <Typography fontSize={13}>
              Temps & demi : <b>{half.toFixed(2)}</b>
            </Typography>
          </Box>
          <Box sx={{ marginTop: '1px' }}>
            <Typography fontSize={13}>
              Km : <b>{distanceTotal.toFixed(2)}</b>
            </Typography>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel control={<Checkbox color="success" checked={approved} onChange={onApprovedChange} disabled={!access || changed || !editable}/>} label={<Typography fontSize={13}><b>Approuvé</b></Typography>} />
            </FormGroup>
          </Box>
        </Box>
        
        
      </Paper>
    </Box>
  );
}

export default SimplePaper;
