import React from 'react';
import { Alert } from '@mui/material';


function SuccessToast() {

  return (
    <div style={{
      position: 'sticky',
      top: 0,
      zIndex: 1000, // Adjust the z-index as needed
      width: '100%',
    }}>
      <Alert severity="success" style={{ borderRadius: 0 }}>
          Rapport enregistré avec succès.
      </Alert>
    </div>
  );
}

export default SuccessToast;
