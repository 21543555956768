import { useState } from 'react';
import Box from '@mui/material/Box';
import { GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import { createSvgIcon } from '@mui/material/utils';
import { fetchTimesheetImport, ChangeAllTimesheetsStatus } from '../../services/fetch';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Dialog, DialogTitle, DialogActions } from '@mui/material';
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate } from 'react-router-dom';

import { useAuthentication } from '../use/login';

export default function CustomToolbar() {
  const { showBoundary } = useErrorBoundary();
  const [authentication] = useAuthentication();
  const [role] = useState(authentication.role);
  const [openDialogStatus, setOpenDialogStatus] = useState(false);
  const navigate = useNavigate();

  const handleOpenDialogStatus = () => {
    setOpenDialogStatus(true);
  };

  const handleCloseDialogStatus = () => {
    setOpenDialogStatus(false);
  };

  const handleOpenCurrentWeek = () => {
    navigate(`/timesheets/current`);
  };

  const ExportIcon = createSvgIcon(
    <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
    'SaveAlt',
  );

  const handleExport = async () => {
    try {
      if (role === 'admin') {
        const exportData = await fetchTimesheetImport();
        const data = exportData.data;
        const filename = exportData.filename;

        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([data]));

        // Create a link element
        const a = document.createElement('a');
        a.href = url;
        a.download = filename; // This will suggest the filename for the download
        document.body.appendChild(a);
        a.click(); // Programmatically click the link to trigger the download
        document.body.removeChild(a); // Clean up the DOM
        window.URL.revokeObjectURL(url); // Free up memory
      } else {
        throw new Error('Not admin')
      }
    } catch (error) {
      showBoundary(error);
    }
  };

  const handleCloseStatus = async () => {
    try {
      if (role === 'admin') {
        handleCloseDialogStatus();
        await ChangeAllTimesheetsStatus();
        window.location.reload();
      } else {
        throw new Error('Not admin');
      }
      
    } catch (error) {
      showBoundary(error);
    }
  };

  const buttonBasePropsExport = {
    color: 'primary',
    size: 'small',
    startIcon: <ExportIcon />,
  };

  const buttonBasePropsStatus = {
    color: 'primary',
    size: 'small',
    startIcon: <CheckCircleOutlineIcon />,
  };

  const buttonBasePropsCurrent = {
    color: 'primary',
    size: 'small',
    startIcon: <NoteAddIcon />,
  };

  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      {role === 'admin' && (<>
        <Button
          {...buttonBasePropsExport}
          onClick={() => handleExport()}
        >
          Exporter la paie en cours
        </Button>
        <Button
          {...buttonBasePropsStatus}
          onClick={() => handleOpenDialogStatus()}
        >
          Changer le statut
        </Button>
        <Dialog open={openDialogStatus} onClose={handleCloseDialogStatus}>
          <DialogTitle>Êtes-vous certain de vouloir fermer les rapports en cours?</DialogTitle>
          <DialogActions>
            <Button autoFocus onClick={handleCloseDialogStatus}>
              Annuler
            </Button>
            <Button onClick={handleCloseStatus} autoFocus>
              Continuer
            </Button>
          </DialogActions>
        </Dialog>
      </>)}
      <Button
        {...buttonBasePropsCurrent}
        onClick={() => handleOpenCurrentWeek()}
      >
        Semaine en cours
      </Button>
    <Box style={{ flex: 1 }} />
    <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}
