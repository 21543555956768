import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const NoteMobile = ({ editable, activity, access, handleUpdateActivity }) => {
  const [note, setNote] = useState('');

  useEffect(() => {
    setNote(activity.comments || '');
  }, [activity])

  const onNoteBlur = (e) => {
    const newNote = e.target.value;
    setNote(newNote);

    const changes = {
      comments: newNote || null
    }
    handleUpdateActivity(changes);
  }

  return (
    <div>
        <Typography sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <b>Commentaires</b>
          <IconButton sx={{ marginLeft: '5px' }}>
            <Tooltip title='Ajouter des commentaires sur votre journée' enterTouchDelay={0}>
              <InfoOutlinedIcon fontSize='small'/>
            </Tooltip>
          </IconButton>
        </Typography>
        <Box
        sx={{
            maxWidth: '100%',
            color: 'white'
        }}
        >
          <TextField fullWidth label="Commentaires" id="fullWidth" InputProps={{ readOnly: !access || !editable }} inputProps={{ maxLength: 100 }} defaultValue={note} onBlur={onNoteBlur}/>
        </Box>
    </div>
  );
}

export default NoteMobile;
