import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { DesktopTimePicker } from '@mui/x-date-pickers';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Box from '@mui/material/Box';

import { getTotal } from '../../../utils/compute';

const SHIFTS = {
  "1": {
    start: "start",
    end: "end",
    meal_time: "meal_time"
  },
  "2": {
    start: "start2",
    end: "end2",
    meal_time: "meal_time2"
  },
  "3": {
    start: "start3",
    end: "end3",
    meal_time: "meal_time3"
  },
}

function ActivityMobile({ editable, activity, access, handleUpdateActivity }) {
  const [shift, setShift] = useState("1");
  const fields = SHIFTS[shift];
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [mealTime, setMealTime] = useState(0);
  const [total, setTotal] = useState(0);

  const handleOnChange = (set, name, value) => {
    if (['start', 'end'].indexOf(name) >= 0) {
      if (!value || isNaN(value.date())) {
        value = null;
      }
      const date = activity['date'];
      if (value && date && date.year) {
        value = value.year(date.year())
          .month(date.month())
          .date(date.date())
      }
    }
    set(value);
    const changes = {
      [fields[name]]: value
    }
    handleUpdateActivity(changes);
  };

  useEffect(() => {
    setStart(activity[fields.start]);
    setEnd(activity[fields.end]);
    setMealTime(activity[fields.meal_time]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity, shift, fields]);

  useEffect(() => {
    setTotal(getTotal(start, end, mealTime));
  }, [start, end, mealTime]);

  return (
    <div>
      <Typography sx={{fontWeight: 'bold', display: 'inline-block'}}>Quart&nbsp;:&nbsp;</Typography>
      <ToggleButtonGroup
        value={shift}
        exclusive
        onChange={(event, newShift) => setShift(newShift || shift)}
        aria-label="quart"
      >
        <ToggleButton value="1" aria-label="1er quart">
          1
        </ToggleButton>
        <ToggleButton value="2" aria-label="2è quart">
          2
        </ToggleButton>
        <ToggleButton value="3" aria-label="3è quart">
          3
        </ToggleButton>
      </ToggleButtonGroup>      
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <Typography sx={{fontWeight: 'bold'}}>Heures de travail</Typography>
        <IconButton sx={{ marginLeft: '5px' }}>
          <Tooltip title={`Inscrire vos heures de début et de fin, ainsi que le temps de repas utilisé dans le quart ${shift}`} enterTouchDelay={0}>
            <InfoOutlinedIcon fontSize='small'/>
          </Tooltip>
        </IconButton>
      </Box>
      <Grid container spacing={3} style={{ display: 'flex', alignItems: 'center' }}>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <DesktopTimePicker
            label="Début"
            value={start}
            onChange={(value) => handleOnChange(setStart, 'start', value)}
            format="HH:mm"
            ampm={false}
            readOnly={!access || !editable}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <DesktopTimePicker
            label="Fin"
            value={end}
            onChange={(value) => handleOnChange(setEnd, 'end', value)}
            format="HH:mm"
            ampm={false}
            readOnly={!access || !editable}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FormControl fullWidth style={{ marginTop: '0px' }}>
            <InputLabel id="demo-simple-select-label">
              <Typography>Temps de repas</Typography>
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={(event) => handleOnChange(setMealTime, 'meal_time', event.target.value)}
              value={mealTime}
              label='Temps de repas'
              readOnly={!access || !editable}
            >
              <MenuItem value='0'><Typography>Aucun</Typography></MenuItem>
              <MenuItem value='0.25'><Typography>15 minutes</Typography></MenuItem>
              <MenuItem value='0.5'><Typography>30 minutes</Typography></MenuItem>
              <MenuItem value='0.75'><Typography>45 minutes</Typography></MenuItem>
              <MenuItem value='1'><Typography>1 heure</Typography></MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center', marginTop: '0px' }}>
          <TextField
            fullWidth
            id="filled-read-only-input"
            label="Heures totales"
            value={total.toFixed(2)}
            InputProps={{
              readOnly: true,
              style: {
                fontWeight: 'bold'
              }
            }}
            variant="filled"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default ActivityMobile;
