import { useState, useEffect, useRef, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import SaveIcon from '@mui/icons-material/Save';

import MoreMenu from './moremenu';
import ActivityMobile from './activity';
import NoteMobile from './note';
import ContractListGrid from './contracts';
import InfoMobile from './info';
import DropzoneMobile from './dropzone';
import PrimaryAppBar from '../../layout/appbar';
import MyModal from './modal';
import EquipListGrid from './equip';
import { UpdateTimesheetStatus, ApplicationError, FetchUser } from '../../../services/fetch'
import Blocker from '../../blocker';
import useProfile from '../../use/profile'

const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

const getAccessValue = (approved, status) => {
  return !approved && (status === 'En cours');
}

const getActivity = (report, currentTab) => {
  return currentTab < 7 ? report.activities[currentTab] : null
}

function MobileTimesheet({ contracts, report, changed, handleUpdateReport, handleSaveReport, handleSetReport }) {
  const [currentTab, setCurrentTab] = useState(0);
  const [activity, setActivity] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [access, setAccess] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [profile] = useProfile();
  const [defaultContract, setDefaultContract] = useState(null);
  const contractsRef = useRef(null);
  const equipsRef = useRef(null);
  const approved = report.is_approved;
  const status = report.status;

  useEffect(() => {
    const newAccess = getAccessValue(approved, status);
    setAccess(newAccess);
  }, [approved, status]);

  useEffect(() => {
    setUpdating(false);
  }, [report]);

  useEffect(() => {
    setActivity(getActivity(report, currentTab));
  }, [report, currentTab]);

  useEffect(() => {
    const effect = async () => {
      let user;
      if (report?.user_id === profile?.id) {
        user = profile;
      } else {
        user = await FetchUser(report.user_id);
      }
      const contract = contracts?.find((contract) => contract.id === user?.default_contract_id);
      setDefaultContract(contract);
    }
    effect();
  }, [report, profile, contracts]);

  const handleUpdateActivity = async (changes) => {
    const newActivity = {...activity, ...changes};
    const newActivities = report.activities.map((activity) => activity.id === newActivity.id ? newActivity : activity);
    const reportChanges = {
      activities: newActivities
    };
    await handleUpdateReport(reportChanges);
  }

  const handleSetCurrentTab = (newTab) => {
    Promise.resolve()
      .then(() => contractsRef.current?.save())
      .then(() => equipsRef.current?.save())
      .then(() => {
        setCurrentTab(newTab);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        // Do nothing
      });
  }

  const handleModalSaveReport = async () => {
    setUpdating(true);
    await handleSaveReport();
  }

  const handleChangeStatus = async (newStatus) => {
    try {
      setUpdating(true);
      const payload = {
        modified_at: report.modified_at,
        status: newStatus,
      };
      const newReport = await UpdateTimesheetStatus(report.id, payload);
      handleSetReport(newReport);
    } catch(error) {
      setUpdating(false);
      if (ApplicationError.is(error, ['outdated_record', 'not_found'])) {
        // TODO: Modal Dialog
        alert("Vous travaillez présentement sur une ancienne version. Svp veuillez actualiser la page.");
      }
      throw error;
    }
  }

  const handleChangeApproved = async (newApproved) => {
    try {
      setUpdating(true);
      const payload = {
        modified_at: report.modified_at,
        is_approved: newApproved,
      };
      const newReport = await UpdateTimesheetStatus(report.id, payload)
      handleSetReport(newReport);
    } catch(error) {
      setUpdating(false);
      if (ApplicationError.is(error, ['outdated_record', 'not_found'])) {
        // TODO: Modal Dialog
        alert("Vous travaillez présentement sur une ancienne version. Svp veuillez actualiser la page.");
      }
      throw error;
    }
  }

  const handleSaveForm = (e) => {
    Promise.resolve()
      .then(() => contractsRef.current?.save())
      .then(() => equipsRef.current?.save())
      .then(() => {
        setModalOpen(true);
      })
      .catch((error) => {
        // Do nothing
      });
  }

  const handleLeave = useCallback(() => {
    return changed || contractsRef.current?.isEditing() || equipsRef.current?.isEditing();
  }, [changed]);

  return (
    <div style={{ backgroundColor: '#f0f0f0' }}>
      <MyModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} handleSaveReport={handleModalSaveReport} handleSetCurrentTab={handleSetCurrentTab} report={report}></MyModal>
      <PrimaryAppBar MoreMenu={MoreMenu} MoreMenuProps={{report_id: report.id}}></PrimaryAppBar>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: 'background.paper', position: 'sticky', top: 0, zIndex: 5 }}>
        <InfoMobile editable={!updating} report={report} approved={approved} changed={changed} handleChangeStatus={handleChangeStatus} handleChangeApproved={handleChangeApproved} /> 
        <Tabs
          value={currentTab}
          onChange={(event, newTab) => handleSetCurrentTab(newTab)}
          centered={false}
          scrollButtons="auto"
          variant="scrollable"
        >
          {daysOfWeek.map((day, index) => (
            <Tab key={index} label={day} />
          ))}
          <Tab key='depenses' label={`Dépenses (${report.expenses?.length})`} />
          <Tab key='equip' label='Équipements' />
        </Tabs>
      </Box>
      <div style={{ backgroundColor: '#f0f0f0', minHeight: '100vh', padding: '16px', maxWidth: '1400px', margin: '0 auto' }}>
        {activity ? (
          <Grid container spacing={2} key={currentTab} sx={{display: currentTab < 7 ? 'block' : 'none'}}>
            <Grid item xs={12}>
              <Box>
                <Box sx={{ backgroundColor: 'white', padding: '12px', borderRadius: '6px' }}>
                  <ActivityMobile editable={!updating} report={report} activity={activity} access={access} handleUpdateActivity={handleUpdateActivity} />
                </Box>
                <Box sx={{ backgroundColor: 'white', padding: '12px', borderRadius: '6px', marginTop: '12px' }}>
                  <ContractListGrid ref={contractsRef} editable={!updating} report={report} contracts={contracts} activity={activity} access={access} defaultContract={defaultContract} handleUpdateActivity={handleUpdateActivity} />
                </Box>
                <Box sx={{ backgroundColor: 'white', padding: '12px', borderRadius: '6px', marginTop: '12px' }}>
                  <NoteMobile editable={!updating} report={report} activity={activity} access={access} handleUpdateActivity={handleUpdateActivity} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        ) : null}
        <Grid container spacing={2} key='depenses' sx={{display: currentTab === 7 ? 'block' : 'none'}}>
          <Grid item xs={12} key='depenses'>
            <Box>
              <Box sx={{ backgroundColor: 'white', padding: '12px', borderRadius: '6px' }}>
                <DropzoneMobile editable={!updating} report={report} access={access} handleUpdateReport={handleUpdateReport} />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} key='equip' sx={{display: currentTab === 8 ? 'block' : 'none'}}>
          <Grid item xs={12} key='equip'>
            <Box>
              <Box sx={{ backgroundColor: 'white', padding: '12px', borderRadius: '6px' }}>
                <EquipListGrid ref={equipsRef} editable={!updating} report={report} access={access} handleUpdateReport={handleUpdateReport} defaultContract={defaultContract}></EquipListGrid>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ paddingTop: '12px', borderRadius: '6px' }}>
          <Button variant="contained" startIcon={<SaveIcon />} onClick={handleSaveForm} disabled={!access || !changed || updating} fullWidth>Enregistrer</Button>
        </Box>
      </div>
      <Blocker onLeave={handleLeave}></Blocker>
    </div>
  );
}


export default MobileTimesheet;
