import { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import whitelogo from '../../assets/cppi-logo-white.png';
import { useAuthentication } from '../use/login';
import TopMenu from './topmenu';
import LeftPanel from './leftpanel';

const PAGES = {
  "timesheets": "Feuilles de temps",
  "timesheet": "Feuille de temps",
  "users": "Utilisateurs",
  "sectors": "Secteurs",
  "equipments": "Équipements",
  "contracts": "Contrats"
}

export default function PrimaryAppBar({page, MoreMenu, MoreMenuProps}) {
  const [authentication] = useAuthentication();
  const [username, setUsername] = useState(authentication.user_name);
  const [role, setRole] = useState(authentication.role);
  const navigate = useNavigate();

  useEffect(() => {
    setUsername(authentication.user_name);
    setRole(authentication.role);
  }, [authentication]);

  const handleBack = () => {
    navigate('/timesheets')
  }

  //const theme = useTheme();
  //const xs = useMediaQuery(theme.breakpoints.up("xs"));
  //const sm = useMediaQuery(theme.breakpoints.up("sm"));
  //const md = useMediaQuery(theme.breakpoints.up("md"));
  //const lg = useMediaQuery(theme.breakpoints.up("lg"));
  //const xl = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: '#343434' }}>
        <Toolbar>

          {page === 'timesheet' ? (
            <IconButton
            size="large"
            edge="start"
            color="inherit"
            onClick={handleBack}
            sx={{ mr: 2 }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>

          ) : (
            <LeftPanel role={role} username={username} />
          )}
          
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            <div style={{ marginTop: '6px', marginRight: '20px', alignContent: 'center' }}>
              <img src={whitelogo} alt="logo" width="175" />
            </div>
            
          </Typography>
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <Box sx={{ color: 'white', display: { xs: 'block', md: 'none' } }}>
              <Typography variant="h7" sx={{ fontFamily: 'Arial, sans-serif' }}>
                {PAGES[page]}
              </Typography>
              </Box>
            </Box>
            <Box sx={{ marginLeft: 2, display: { xs: 'none', md: 'block' } }}>
              <TopMenu role={role} />
            </Box>
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          {MoreMenu ? (
            <MoreMenu {...MoreMenuProps} />
          ) : null}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
