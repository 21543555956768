import { useState, Fragment } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { timeAllocationErrorCheck, workHoursErrorCheck } from '../../../utils/compute';
import ModalList from './modalErrorList';

export default function MyModal({isOpen, onClose, handleSaveReport, handleSetCurrentTab, report}) {
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');
  const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

  const onSaveAndClose = () => {
    handleSaveReport()
      .then(() => {
        onClose();
      })
      .catch((error) => {
        // Do nothing
      });
  }

  const onErrorClick = (idx) => {
    handleSetCurrentTab(idx);
    onClose();
  };

  const errorCheck = () => {
    const error_reporting = [];

    report.activities.forEach((activity, index) => {
      if (workHoursErrorCheck(activity)) {
        const temp = {
          tab: index,
          day: daysOfWeek[index],
          error: "Heures de travail invalides",
          severity: "error"
        }

        error_reporting.push(temp);
      }

      if (timeAllocationErrorCheck(activity)) {
        const temp = {
          tab: index,
          day: daysOfWeek[index],
          error: "Temps de contrats alloués invalides",
          severity: "warning"
        }

        error_reporting.push(temp);
      }

    })
    return error_reporting;
  };

  const errorLog = errorCheck();

  return (
    <Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={isOpen}
        onClose={onClose}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vous êtes sur le point d'enregistrer la totalité du rapport. Voulez-vous continuer?
          </DialogContentText>
          <ModalList error_log={errorLog} onClick={onErrorClick}></ModalList>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} autoFocus>Annuler</Button>
          <Button onClick={onSaveAndClose} autoFocus>Continuer</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
