let GENERATED_IDS = [];

export const randomInt32 = () => window.crypto.getRandomValues(new Int32Array(1))[0];

const _newId = () => {
  // Always returns a negative id to mean "new" so it will be generated by the backend.
  const id = randomInt32();
  if (id < 0) {
    return id;
  }
  return -id;
};

export const newId = () => {
  // Returns a unique negative id to mean "new" so it will be generated by the backend.
  let id = _newId();
  while (GENERATED_IDS.indexOf(id) >= 0) {
    id = _newId();
  }
  GENERATED_IDS.push(id);
  return id;
}

export const resetIds = () => {
  // Reset generated ids
  GENERATED_IDS = [];
}