import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';

function ImageMobile({editable, access, expense, idx, handleDelete}) {
  const getImageUrl = (expense) => {
    if (expense instanceof File) {
      return URL.createObjectURL(expense);
    }
    return expense.url;
  }

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      {editable && access ? (
        <CancelIcon
          style={{
            position: 'absolute',
            top: '5px', // Adjust as needed
            right: '5px', // Adjust as needed
            cursor: 'pointer',
          }}
          onClick={() => {
            handleDelete(idx);
          }}
        />
      ) : null}
      <img
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
        src={getImageUrl(expense)}
        alt=""
      />
    </div>
  );
}

export default ImageMobile;
