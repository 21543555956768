import jwt_decode from "jwt-decode";

let _authCache = null;

function buildAuthCache() {
  const token = localStorage.getItem('token')
  const tokenData = token && jwt_decode(token);
  if (!tokenData || !tokenData.user_name) {
    logout();
    return {};
  }
  _authCache = {
    token,
    refresh: localStorage.getItem('refresh'),
    ...tokenData,
  }
  return _authCache;
}

const setAuthentication = (authentication) => {
  _authCache = null;
  localStorage.setItem('token', authentication.token);
  localStorage.setItem('refresh', authentication.refresh);
}

export function useAuthentication() {
  const authentication = _authCache || buildAuthCache();
  return [authentication, setAuthentication];
}

export function logout() {
  _authCache = null
  localStorage.removeItem('token');
  localStorage.removeItem('refresh');
}

export function isAuthenticated(authentication) {
  // Return true if the token is valid, false otherwise
  return !!authentication && !!authentication.token;
}

export function isRole(authentication, role) {
  if (!role || authentication.role === role) {
    return true;
  } else {
    return false
  }
}
