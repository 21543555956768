import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import { SignIn } from '../components/login/sign_in'
import { isConnected } from '../utils/jwt';

export function SignInPage() {
  const navigate = useNavigate();
  const is_connected = isConnected();

  useEffect(() => {
    if (is_connected) {
      navigate('/timesheets');
    }
  }, [is_connected, navigate]);

  if (!is_connected) {
    return (
      <div>
        <SignIn></SignIn>
      </div>
    );
  }

  // You can return null here since the navigation will occur in the effect
  return null;
}
