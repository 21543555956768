
import Box from '@mui/material/Box';
import {
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

export function NotificationsToolbar({handleOpenDialog}) {
  return (
    <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <GridToolbarFilterButton />
        <Box ml={1}>
            <GridToolbarExport />
        </Box>
        <Box ml={1}>
          <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon/>}
          onClick={() => handleOpenDialog(null)}
          size='small'
          sx={{ marginLeft: '8px',
                "& .MuiButton-startIcon": {
                    marginRight: 'auto',
                    marginLeft: 'auto',
                }
          }}
          />
        </Box>
      </Box>
      <Box>
        <GridToolbarQuickFilter />
      </Box>
    </GridToolbarContainer>
  );
}