import { useState, useEffect } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { CreateSector, DeleteSector, FetchSectors, UpdateSector } from "../services/fetch";

import SectorList from "../components/sectors/sectors";
import PrimaryAppBar from "../components/layout/appbar";
import { ApplicationError } from "../services/fetch";


export function SectorListPage() {
  const [sectors, setSectors] = useState([]);
    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
      FetchSectors(true)
        .then((response) => {
          setSectors(response);
        })
        .catch((error) => {
          showBoundary(error);
        });
    }, [showBoundary]);


    const handleSectorUpdate = async (sector_id, data) => {
        try {
          const newSector = await UpdateSector(sector_id, data)
          const newSectors = sectors.map((sector) => (sector.id === sector_id ? newSector : sector));
          setSectors(newSectors);
          return newSector;
        } catch(error) {
          if (ApplicationError.is(error, ['outdated_record', 'not_found'])) {
            alert("Vous travaillez présentement sur une ancienne version. Svp veuillez actualiser la page.");
          } else {
            showBoundary(error);
          }
          throw error;
        }
      };
    
      const handleSectorCreation = async (data) => {
        try {
          const newSector = await CreateSector(data)
          const newSectors = [newSector, ...sectors];
          setSectors(newSectors);
          return newSector;
        } catch(error) {
          showBoundary(error);
        }
      };
    
      const handleSectorDeletion = async (sector_id) => {
        try {
          await DeleteSector(sector_id);
          const newSectors = sectors.filter((sector) => sector.id !== sector_id);
          setSectors(newSectors);
        } catch(error) {
          showBoundary(error);
        }
      };


  return (
    <div>
      <PrimaryAppBar page='sectors'></PrimaryAppBar>
      <SectorList sectors={sectors} handleSectorCreation={handleSectorCreation} handleSectorUpdate={handleSectorUpdate} handleSectorDeletion={handleSectorDeletion}></SectorList>
    </div>
  );
}
