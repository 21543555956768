import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Ensure you import jspdf-autotable
import { roundToQuarter } from '../utils/compute';
import { fetchTimesheetSummary } from '../services/fetch';
import logo from '../assets/cppi-logo-black2.png'; // Update the path to your logo file
import { deserializeTime, deserializeDate, setTimezone, formatTimeWithoutSeconds, formatDate, formatDateTime } from '../utils/dates'
import dayjs from 'dayjs'

function groupAndSumContracts(arrayOfArrays) {
  // Use an object to store the accumulated values for each name
  const groupedByName = arrayOfArrays.reduce((acc, arr) => {
    const [name, shift, km, reg, sup, tot] = arr;
    const key = name + '|' + shift;
    // If the name is already in the accumulator, add up the values; otherwise, initialize it
    if (!acc[key]) {
      acc[key] = [name, shift, 0, 0, 0, 0];
    }
    acc[key][2] += km;
    acc[key][3] += roundToQuarter(reg);
    acc[key][4] += roundToQuarter(sup);
    acc[key][5] += roundToQuarter(tot);
    return acc;
  }, {});

  // Convert the grouped object into an array of arrays
  const resultArray = Object.values(groupedByName);

  return resultArray;
}


function updateTimes(my_array) {

  for (let i = 0; i < my_array.length; i++) {
    const date = deserializeDate(my_array[i][0]);
    my_array[i][0] = formatDate(date);
    if (my_array[i][2] !== '-') {
      my_array[i][2] = formatTimeWithoutSeconds(setTimezone(deserializeTime(my_array[i][2], date)));
    }
    if (my_array[i][3] !== '-') {
      my_array[i][3] = formatTimeWithoutSeconds(setTimezone(deserializeTime(my_array[i][3], date)));
    }
    if (my_array[i][1] !== 'Total') {
      my_array[i][5] = roundToQuarter(my_array[i][5]);
      my_array[i][6] = roundToQuarter(my_array[i][6]);
      my_array[i][7] = roundToQuarter(my_array[i][7]);
    }
  }
}

function updateEquips(my_array) {

  for (let i = 0; i < my_array.length; i++) {
    if (my_array[i].length >= 3) {
      my_array[i][2] = roundToQuarter(my_array[i][2]);
    }
  }
}


function checkNull(variable) {
  if (variable === null) {
      return '';
  } else {
      return variable;
  }
}


const jsPdfGenerator = async (summary) => {
  const doc = new jsPDF('p', 'pt');

  if (typeof doc.autoTable !== 'function') {
    throw new Error("jspdf: autoTable function is not available in the 'doc'.");
  }

  const MARGIN_TOP = 30;
  const MARGIN_BOTTOM = 30;
  const MARGIN_LEFT = 30;
  const MARGIN_RIGHT = 30;
  const PAGE_WIDTH = doc.internal.pageSize.width - MARGIN_LEFT - MARGIN_RIGHT;
  const PAGE_HEIGHT = doc.internal.pageSize.height - MARGIN_TOP - MARGIN_BOTTOM;

  // Add a logo to the leftmost part of the PDF
  const logoImg = new Image();
  await new Promise((resolve, reject) => {
    logoImg.onload = resolve;
    logoImg.onerror = reject;
    logoImg.src = logo;
  });
  doc.addImage(logoImg, 'PNG', 340, MARGIN_TOP);

  // Set font styles
  doc.setFont('times', 'normal');
  doc.setFontSize(16);

  // Calculate the height of the logo
  const logoHeight = logoImg.height;

  // Add a header
  doc.setTextColor(0, 0, 0);
  doc.setFont(undefined, 'bold');
  doc.setFontSize(18);
  doc.text('Feuille de temps', 63, 100);

  doc.setFont(undefined, 'normal');
  doc.setFontSize(11);

  doc.line(55, 155, 530, 155);
  doc.line(55, 185, 530, 185);
  doc.line(55, 215, 530, 215);
  doc.line(55, 245, 530, 245);
  doc.line(55, 275, 530, 275);
  
  doc.line(285, 155, 285, 275);

  doc.setFontSize(11);
  doc.setFont(undefined, 'bold');

  doc.text("Nom de l'employé : ", 63, 175);
  doc.text("Approuvé par : ", 305, 175);
  doc.text("Numéro de l'employé : ", 63, 205);
  doc.text("Période finissante le : ", 305, 205);
  doc.text("Heures à temps simple : ", 63, 235);
  doc.text("Heures à temps et demi : ", 305, 235);
  doc.text("Kilomètres : ", 63, 265);
  doc.text("Dépenses: ", 305, 265)


  doc.setFont(undefined, 'normal');
   
  doc.text(`${summary.employee_name}`, 160, 175);
  doc.text(`${checkNull(summary.approved_by)}`, 390, 175);
  doc.text(`${summary.employee_number}`, 175, 205);
  doc.text(`${formatDate(deserializeDate(summary.end))}`, 415, 205);
  doc.text(`${roundToQuarter(summary.simple_time)} heures`, 180, 235);
  doc.text(`${roundToQuarter(summary.time_and_a_half)} heures`, 435, 235);
  doc.text(`${roundToQuarter(summary.distance)} km`, 128, 265);
  doc.text(`${summary.expenses.length}`, 375, 265);


  // Times Table
  let data = summary.days
  updateTimes(data);
  let tableY = 250 + logoHeight;
  let columns = ['Date', 'Quart', 'Début', 'Fin', 'Repas', 'Temps rég.', 'Temps demi.', "Total", "Commentaire"];
  let columnStyles = {
    0: { cellWidth: 60 },
    1: { cellWidth: 45 },
    2: { cellWidth: 45 },
    3: { cellWidth: 45 },
    4: { cellWidth: 71 },
    5: { cellWidth: 71 },
    6: { cellWidth: 71 },
  };
  doc.autoTable({
    head: [
      [{ content: 'Jours de travail', colSpan: columns.length, styles: { halign: 'center', fillColor: [242, 133, 33] } }],
      columns
    ],
    body: data,
    startY: tableY,
    theme: 'grid',
    bodyStyles: {
      lineWidth: 0.1,
      lineColor: [0, 0, 0], // Border color (black)
      textColor: [0, 0, 0]
    },
    headStyles: {
      lineWidth: 0.2,
      lineColor: [0, 0, 0], // Border color (black)
      fillColor: [220, 220, 220],
      textColor: [0, 0, 0]
    },
    columnStyles: columnStyles,
    didParseCell(data) {
      if (data.row.raw[1] === 'Total') {
        Object.values(data.row.cells).forEach((cell) => cell.styles.fontStyle = 'bold')
      }
    }
  });

  // Contracts Table
  data = groupAndSumContracts(summary.contracts);
  tableY = doc.autoTable.previous.finalY + 30;
  columns = ['Contrat', 'Quart', 'KM', 'Temps rég.', 'Temps demi.', "Total"];
  columnStyles = {
    1: { cellWidth: 38 },
    2: { cellWidth: 38 },
    3: { cellWidth: 69 },
    4: { cellWidth: 71 },
    5: { cellWidth: 67 }
 };
  doc.autoTable({
    head: [
      [{ content: 'Contrats', colSpan: columns.length, styles: { halign: 'center', fillColor: [242, 133, 33] } }],
      columns
    ],
    body: data,
    startY: tableY,
    theme: 'grid',
    bodyStyles: {
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0]
    },
    headStyles: {
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
      fillColor: [220, 220, 220],
      textColor: [0, 0, 0]
    },
    columnStyles: columnStyles
  });

  // Equipments Table
  data = summary.equips;
  updateEquips(data);
  tableY = doc.autoTable.previous.finalY + 30;
  columns = ['Équipement', 'Contrat', 'Temps'];
  columnStyles = {
    0: { cellWidth: 222 },
    1: { cellWidth: 221 },
    2: { cellWidth: 71 }
  }  
  doc.autoTable({
    head: [
      [{ content: 'Équipements', colSpan: columns.length, styles: { halign: 'center', fillColor: [242, 133, 33] } }],
      columns
    ],
    body: data,
    startY: tableY,
    theme: 'grid',
    bodyStyles: {
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0]
    },
    headStyles: {
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
      fillColor: [220, 220, 220],
      textColor: [0, 0, 0]
    },
    columnStyles: columnStyles
  });

  // Expenses Images (one per page)
  await Promise.all(summary.expenses.map((expense) => {
    const [url] = expense;

    const img = new Image();
    
    return new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = url;
      })
      .then(() => {
        let width  = img.width;
        let height = img.height;
        const ratio = width / height;

        if (width > PAGE_WIDTH) {
          width = PAGE_WIDTH;
          height = PAGE_WIDTH / ratio;
        } else if (height > PAGE_HEIGHT) {          
          width  = PAGE_HEIGHT * ratio;
          height = PAGE_HEIGHT;
        }
        
        // Watchdog
        if (width > PAGE_WIDTH) {
          width = PAGE_WIDTH;
        }
        if (height > PAGE_HEIGHT) {
          height = PAGE_HEIGHT;
        }
        
        doc.addPage();
        doc.addImage(img, 'PNG', MARGIN_LEFT, MARGIN_TOP, width, height);
      })
  }));

  // Pages Footer
  const printFooters = () => {
    const now = formatDateTime(dayjs());
    const pageCount = doc.internal.getNumberOfPages()
    doc.setFontSize(10)
    const y = doc.internal.pageSize.height - MARGIN_BOTTOM + 12; // TODO: why must we do "+ 12" ???
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i)
      doc.text(`Généré le: ${now}`, 40, y);
      doc.setFont(undefined, 'bold');
      doc.text(`***Dépenses (${summary.expenses.length})`, 490, y);
      doc.setFont(undefined, 'normal');
    }
  };
  printFooters();

  // Save the PDF
  doc.save('timesheet_report.pdf');
};

const fetchDataAndGeneratePdf = async (report_id) => {
  const summary = await fetchTimesheetSummary(report_id);
  await jsPdfGenerator(summary);
};

export default fetchDataAndGeneratePdf;
