
import { useState } from 'react';
import Box from '@mui/material/Box';
import {
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import { newId } from '../../utils/random';

export function ContractsToolbar({handleContractCreation}) {
  const [openDialogNew, setOpenDialogNew] = useState(false);
  const [number, setNumber] = useState("");
  const [description, setDescription] = useState("");

  const handleNumberChange = (event) => {
    setNumber(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleOpenDialogNew = () => {
    setOpenDialogNew(true);
  };

  const handleCloseDialogNew = () => {
    setOpenDialogNew(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      id: newId(),
      value: number,
      label: description,
      is_active: true
    };
    await handleContractCreation(payload);
    setOpenDialogNew(false);
    return false;
  };

  return (
    <>
      <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <GridToolbarFilterButton />
          <Box ml={1}>
              <GridToolbarExport />
          </Box>
          <Box ml={1}>
            <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon/>}
            onClick={handleOpenDialogNew}
            size='small'
            sx={{ marginLeft: '8px',
              "& .MuiButton-startIcon": {
                marginRight: 'auto',
                marginLeft: 'auto',
              }
            }}
            />
          </Box>
        </Box>
        <Box>
          <GridToolbarQuickFilter />
        </Box>
      </GridToolbarContainer>
      <Dialog 
        open={openDialogNew} 
        onClose={handleCloseDialogNew} 
        PaperProps={{
            component: 'form',
            onSubmit: handleSubmit
        }}
      >
          <DialogTitle>Créer un nouveau contrat</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Pour inscrire un contrat dans Tempo, veuillez entrer les informations ci-dessous.
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="number"
                  defaultValue={number}
                  onChange={handleNumberChange}
                  label="Numéro de contrat"
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  required
                  margin="dense"
                  id="description"
                  defaultValue={description}
                  onChange={handleDescriptionChange}
                  label="Description"
                  type="text"
                  rows={4}
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogNew} color="primary">
              Annuler
            </Button>
            <Button type="submit" color="primary">
              Ajouter
            </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}