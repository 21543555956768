import { useState, useEffect } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import Box from '@mui/material/Box';
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { FileOpen } from '@mui/icons-material';

import { FetchNotifications, CreateNotification, UpdateNotification, DeleteNotification, FetchNotification, ApplicationError } from '../../services/fetch';
import { dateTimeGetter, dateTimeFormatter } from '../mui_utils'
import { NotificationsToolbar } from './notifications_toolbar';
import { NotificationDialog } from './notification';

export default function NotificationsList({mode}) {
  const [open, setOpen] = useState(false);
  const [ notifications, setNotifications ] = useState([]);
  const [ notification, setNotification ] = useState(null);
  const { showBoundary } = useErrorBoundary();
  
  useEffect(() => {
    FetchNotifications(mode)
      .then((response) => {
        setNotifications(response);
      })
      .catch((error) => {
        setNotifications([])
        showBoundary(error);
      });
  }, [showBoundary, mode, setNotifications]);

  const handleOpenDialog = async (notification) => {
    try {
      if (!notification) {
        setOpen(true);
        return;
      }
      // Get fresh data
      const newNotification = await FetchNotification(notification.id);
      setNotification(newNotification)
      const newNotifications = notifications.map((notification) => (notification.id === newNotification.id ? newNotification : notification));
      setNotifications(newNotifications);
      setOpen(true);
    } catch(error) {
      showBoundary(error);
    };
  }

  const handleCloseDialog = () => {
    setOpen(false);
  }

  const handleNotificationUpdate = async (notificationId, data) => {
    try {
      const newNotification = await UpdateNotification(notificationId, data)
      const newNotifications = notifications.map((notification) => (notification.id === notificationId ? newNotification : notification));
      setNotifications(newNotifications);
      handleCloseDialog();
    } catch(error) {
      if (ApplicationError.is(error, ['outdated_record', 'not_found'])) {
        // TODO: Modal Dialog
        alert("Vous travaillez présentement sur une ancienne version. Svp veuillez actualiser la page.");
      } else {
        showBoundary(error);
      }
      throw error;
    }
  };

  const handleNotificationCreation = async (data) => {
    try {
      const newNotification = await CreateNotification(data)
      const newNotifications = [newNotification, ...notifications]
      setNotifications(newNotifications);
      handleCloseDialog();
    } catch(error) {
      showBoundary(error);
    }
  };

  const handleNotificationDeletion = async (notificationId) => {
    try {
      await DeleteNotification(notificationId);
      const newNotifications = notifications.filter((notification) => notification.id !== notificationId);
      setNotifications(newNotifications);
    } catch(error) {
      showBoundary(error);
    }
  };

  let columns = [
    {
        field: 'subject',
        headerName: 'Sujet',
        renderHeader: () => (
            <strong>
              {'Sujet'}
            </strong>
        ),
        type: 'string',
        flex: 0.6,
        editable: false
    },
    {
      field: 'expire_at',
      headerName: 'Expiration',
      renderHeader: () => (
          <strong>
            {'Expire le'}
          </strong>
      ),
      valueGetter: dateTimeGetter,
      valueFormatter: dateTimeFormatter,
      type: 'dateTime',
      flex: 2,
      editable: false
    },
    {
      field: 'created_at',
      headerName: 'Creation',
      renderHeader: () => (
          <strong>
            {'Créé le'}
          </strong>
      ),
      valueGetter: dateTimeGetter,
      valueFormatter: dateTimeFormatter,
      type: 'dateTime',
      flex: 2,
      editable: false
    },
    {
      field: 'modified_at',
      headerName: 'Modification',
      renderHeader: () => (
          <strong>
            {'Modifié le'}
          </strong>
      ),
      valueGetter: dateTimeGetter,
      valueFormatter: dateTimeFormatter,
      type: 'dateTime',
      flex: 2,
      editable: false
    },
    {
      field: 'action',
      type: 'actions',
      flex: 0.4,
      renderCell: (params) => (mode === 'edit' ? (
        <>
          <GridActionsCellItem
            icon={<EditIcon style={{ color: '#343434' }} />}
            label="Modifier"
            className="textPrimary"
            onClick={(e) => handleOpenDialog(params.row)}
            color="inherit"
          />
          <GridActionsCellItem
            icon={<DeleteIcon style={{ color: '#343434' }}/>}
            label="Delete"
            className="textPrimary"
            onClick={(e) => handleNotificationDeletion(params.row.id)}
            color="inherit"
          />
        </>
      ) : (
        <>
          <GridActionsCellItem
            icon={<FileOpen style={{ color: '#343434' }} />}
            label="Open"
            className="textPrimary"
            onClick={(e) => handleOpenDialog(params.row)}
            color="inherit"
          />
        </>
      ))
    }
  ]

  const theme = useTheme();
  //const xs = useMediaQuery(theme.breakpoints.up("xs"));
  //const sm = useMediaQuery(theme.breakpoints.up("sm"));
  //const md = useMediaQuery(theme.breakpoints.up("md"));
  //const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));


  //let columnVisibility;
  let leftPadding = 0;
  let rightPadding = 0;
  let raidusBorder = 0;

  if (xl) {

    leftPadding = 25
    rightPadding = 25
    raidusBorder = 0

  };

  return (
    <div style={{ backgroundColor: '#f0f0f0', minHeight: '100vh', paddingTop: '0.1px' }}>
      <Box sx={{ backgroundColor: 'white' }}>
        <Box sx={{ backgroundColor: '#f0f0f0', paddingTop: 0, paddingBottom: 5, paddingLeft: leftPadding, paddingRight: rightPadding }}>
        <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: raidusBorder }}>
          <DataGridPro
            getRowId={(row) => row.id}
            editMode="row"
            pagination
            disableColumnSelector
            disableDensitySelector
            columns={columns}
            rows={notifications}
            rowHeight={38}
            slots={{ toolbar: NotificationsToolbar }}
            slotProps={{
              toolbar: {
                mode,
                handleOpenDialog,
              },
            }}
            disableRowSelectionOnClick
          />
          </Box>
        </Box>
      </Box>
      <NotificationDialog mode={mode} open={open} notification={notification} handleClose={handleCloseDialog} handleNotificationCreation={handleNotificationCreation} handleNotificationUpdate={handleNotificationUpdate} />
    </div>
  );
}