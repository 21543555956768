
import { useState } from 'react';
import Box from '@mui/material/Box';
import {
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';


export function EquipsToolbar({handleEquipCreation}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [description, setDescription] = useState("");

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      label: description,
      is_active: true
    };
    await handleEquipCreation(payload);
    setOpenDialog(false);
    return false;
  };

  return (
    <>
      <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <GridToolbarFilterButton />
          <Box ml={1}> {/* Adjust the margin as needed */}
              <GridToolbarExport />
          </Box>
          <Box ml={1}> {/* Adjust the margin as needed */}
            <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon/>}
            onClick={handleOpenDialog}
            size='small'
            sx={{ marginLeft: '8px',
                  "& .MuiButton-startIcon": {
                      marginRight: 'auto',
                      marginLeft: 'auto',
                  }
            }}
            />
          </Box>
        </Box>
        <Box>
          <GridToolbarQuickFilter />
        </Box>
      </GridToolbarContainer>

      {/* Dialog */}
      <Dialog 
          open={openDialog} 
          onClose={handleCloseDialog} 
          PaperProps={{
              component: 'form',
              onSubmit: handleSubmit
          }}
      >
          <DialogTitle>Créer un nouvel équipement</DialogTitle>
          <DialogContent>
              <DialogContentText>
                  Pour inscrire un équipement dans Tempo, veuillez entrer les informations ci-dessous.
              </DialogContentText>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                      <TextField
                          required
                          margin="dense"
                          id="description"
                          defaultValue={description}
                          onChange={handleDescriptionChange}
                          label="Description"
                          type="text"
                          rows={4}
                          fullWidth
                      />
                  </Grid>
              </Grid>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                  Annuler
              </Button>
              <Button type="submit" color="primary">
                  Ajouter
              </Button>
          </DialogActions>
          </Dialog>
      
    </>
  );
}