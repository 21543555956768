import './App.css';
import ProtectedRoute from './ProtectedRoute';
import { SignInPage } from './views/sign_in'
import { UserListPage } from './views/users';
import { TimesheetsPage } from './views/timesheets';
import { ContractListPage } from './views/contracts';
import { EquipmentListPage } from './views/equipments';
import { SectorListPage } from './views/sectors';
import { NotificationsListPage } from './views/notifications';
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route, Navigate } from 'react-router-dom';
import { TimesheetPage } from './views/timesheet';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path="" element={<SignInPage />} />
      <Route path="users" element={<ProtectedRoute role="admin" element={<UserListPage />} />} />
      <Route path="contracts" element={<ProtectedRoute role="admin" element={<ContractListPage />} />} />
      <Route path="equips" element={<ProtectedRoute role="admin" element={<EquipmentListPage />} />} />
      <Route path="sectors" element={<ProtectedRoute role="admin" element={<SectorListPage />} />} />
      <Route path="notifications" element={<ProtectedRoute role="" element={<NotificationsListPage />} />} />
      <Route path="timesheets" element={<ProtectedRoute role="" element={<TimesheetsPage />} />} />
      <Route path="timesheets/current" element={<ProtectedRoute role="" element={<TimesheetPage />} />} />
      <Route path="timesheets/:id" element={<ProtectedRoute role="" element={<TimesheetPage />} />} />
      <Route path="*" element={<Navigate to="/" />} /> {/* Handle unknown routes */}
    </Route>
  )
);

function App() {
  return (
    <RouterProvider router={router} future={{
      v7_startTransition: true,
      v7_relativeSplatPath: true,
    }}/>
  );
}


export default App;
