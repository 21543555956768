import { useState, useEffect } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { FetchEquips, UpdateEquip, DeleteEquip, CreateEquip } from "../services/fetch";


import EquipsList from "../components/equips/equips";
import PrimaryAppBar from "../components/layout/appbar";
import { ApplicationError } from '../services/fetch';

export function EquipmentListPage() {
  const [equips, setEquips] = useState([]);
  const { showBoundary } = useErrorBoundary();
    
  useEffect(() => {
    FetchEquips(true)
      .then((response) => {
        setEquips(response);
      })
      .catch((error) => {
        showBoundary(error);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEquipUpdate = async (equip_id, data) => {
    try {
      const newEquip = await UpdateEquip(equip_id, data)
      const newEquips = equips.map((equip) => (equip.id === equip_id ? newEquip : equip));
      setEquips(newEquips);
      return newEquip;
    } catch(error) {
      if (ApplicationError.is(error, ['outdated_record', 'not_found'])) {
        alert("Vous travaillez présentement sur une ancienne version. Svp veuillez actualiser la page.");
      } else {
        showBoundary(error);
      }
      throw error;
    }
  };

  const handleEquipDeletion = async (equip_id) => {
    try {
      await DeleteEquip(equip_id);
      const newEquips = equips.filter((equip) => equip.id !== equip_id);
      setEquips(newEquips);
    } catch(error) {
      showBoundary(error);
    }
  };

  const handleEquipCreation = async (data) => {
    try {
      const newEquip = await CreateEquip(data);
      const newEquips = [newEquip, ...equips];
      setEquips(newEquips);
      return newEquip;
    } catch(error) {
      showBoundary(error);
    }
  };


  return (
    <div>
      <PrimaryAppBar page='equipments'></PrimaryAppBar>
      <EquipsList equips={equips} handleEquipUpdate={handleEquipUpdate} handleEquipDeletion={handleEquipDeletion} handleEquipCreation={handleEquipCreation}></EquipsList>
    </div>
  );
}
