import { useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { Navigate } from 'react-router-dom';

import { logout, useAuthentication, isAuthenticated, isRole } from './components/use/login';
import useProfile from './components/use/profile';
import { fetchUserProfile } from './services/fetch';
import { CircularProgress } from '@mui/material';

// Element to protect routes by checking authentication
export default function ProtectedRoute({ element, role }) {
  const [authentication] = useAuthentication();
  const [currentElement, setCurrentElement] = useState(<CircularProgress/>);
  // eslint-disable-next-line no-unused-vars
  const [_, setProfile] = useProfile();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    async function effect() {
      if (!isAuthenticated(authentication)) {
        logout();
        setCurrentElement(<Navigate to="/" />);
      } else if (!isRole(authentication, role)) {
        setCurrentElement(<Navigate to="/" />);
      } else {
        const profile = await fetchUserProfile();
        setProfile(profile);
        setCurrentElement(element);
      }
    };
    effect()
      .catch((error) => {
        showBoundary(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, role, authentication]);

  return (<>
    {currentElement}
  </>);
};
