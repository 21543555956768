import { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';

import { newId } from '../../utils/random';

export const NotificationDialog = ({mode, open, notification, handleClose, handleNotificationCreation, handleNotificationUpdate}) => {
  const [id, setId] = useState(0);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (notification) {
      setId(notification.id);
      setSubject(notification.subject);
      setMessage(notification.message);
      setUrl(notification.url);
    } else {
      setId(newId());
      setSubject("");
      setMessage("");
      setUrl(null);
    }
  }, [notification])

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleUrlChange = (event) => {
    setUrl(event.target.value || null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      ...notification,
      id,
      subject,
      message,
      url
    };
    if (notification) {
      handleNotificationUpdate(id, payload);
    } else {
      handleNotificationCreation(payload);
    }
    return false;
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose} 
      PaperProps={{
          component: 'form',
          onSubmit: handleSubmit
      }}
    >
      <DialogTitle>Notification</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            {mode === 'edit' ? (
              <TextField
                autoFocus
                required
                margin="dense"
                id="subject"
                value={subject}
                onChange={handleSubjectChange}
                label="Sujet"
                type="text"
                inputProps={{ maxLength: 40 }}
                fullWidth
              />
            ) : (
              <Typography
                margin="dense"
                fontWeight="bold"
                fullWidth
              >
                {subject}
              </Typography>
            )}
          </Grid>
          <Grid item xs={8}>
            {mode === 'edit' ? (
              <TextField
                required
                margin="dense"
                id="message"
                value={message}
                onChange={handleMessageChange}
                label="Message"
                type="text"
                inputProps={{ maxLength: 512 }}
                multiline
                rows={8}
                fullWidth
              />
            ) : (
              <Typography
                margin="dense"
                fullWidth
              >
                {message}
              </Typography>
            )}
          </Grid>
          <Grid item xs={8}>
            {mode === 'edit' ? (
              <TextField
                margin="dense"
                id="url"
                value={url || ''}
                onChange={handleUrlChange}
                label="Lien"
                type="text"
                inputProps={{ maxLength: 1024 }}
                rows={8}
                fullWidth
              />
            ) : (url ? (
              <Typography variant="body1">
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer" // Important for security
                >
                  Plus d'information
                </a>
              </Typography>
            ) : null)}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {mode === 'edit' ? (<>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button type="submit" color="primary">
            Enregistrer
          </Button>
        </>) : (
          <Button onClick={handleClose} color="primary">
            Fermer
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
