import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { dateTimeGetter, dateTimeFormatter } from '../mui_utils';
import { SectorsToolbar } from './sectors_toolbar';
import { ApplicationError } from '../../services/fetch';


export default function SectorList({sectors, handleSectorUpdate, handleSectorCreation, handleSectorDeletion}) {
  const [rows, setRows] = useState([]);
  const [updating, setUpdating] = useState(false);
  
  useEffect(() => {
    setRows(sectors);
    setUpdating(false);
  }, [sectors]);

  let columns = [
    {
      field: 'name',
      headerName: 'Nom',
      renderHeader: () => (
          <strong>
            {'Nom'}
          </strong>
      ),
      type: 'string',
      flex: 2.6,
      editable: !updating,
    },
    {
      field: 'is_active',
      headerName: 'Actif',
      renderHeader: () => (
          <strong>
            {'Actif'}
          </strong>
      ),
      type: 'boolean',
      headerAlign: 'left',
      align: 'left',
      flex: 0.4,
      renderCell: (params) => {
        const handleSwitchChange = async (event, checked) => {
          try {
            if (updating) {
                return false;
            }
            event.target.disabled = true;
            setUpdating(true);
            const sector_id = params.row.id
            const payload = {
              ...params.row,
              is_active: checked,
            };
            await handleSectorUpdate(sector_id, payload);
            return false;
          } catch(error) {
            event.target.disabled = false;
            setUpdating(false);
            return false;
          }
        };

        return (
          <Box>
            <Switch
              checked={params.row.is_active}
              onChange={handleSwitchChange}
              disabled={updating}
            />
          </Box>
        )
      }
    },
    {
      field: 'created_at',
      headerName: "Créé le",
      renderHeader: () => (
        <strong>
          {'Créé le'}
        </strong>
      ),
      type: 'dateTime',
      valueGetter: dateTimeGetter,
      valueFormatter: dateTimeFormatter,
      flex: 0.6
    },
    {
      field: 'action',
      type: 'actions',
      flex: 0.4,
      renderCell: (params) => {
        const sector_id = params.row.id;

        const handleClick = async () => {
          setUpdating(true);
          await handleSectorDeletion(sector_id);
        };
            
        return (
          <IconButton
            size="large"
            color="inherit"
            onClick={handleClick}
          >
            <DeleteIcon style={{ color: '#343434' }}/>
          </IconButton>
        );
      }
    }
  ]

  const theme = useTheme();
  //const xs = useMediaQuery(theme.breakpoints.up("xs"));
  //const sm = useMediaQuery(theme.breakpoints.up("sm"));
  //const md = useMediaQuery(theme.breakpoints.up("md"));
  //const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));


  //let columnVisibility;
  let leftPadding = 0;
  let rightPadding = 0;
  let raidusBorder = 0;

  if (xl) {

    leftPadding = 25
    rightPadding = 25
    raidusBorder = 0

  };


  return (
    <div style={{ backgroundColor: '#f0f0f0', minHeight: '100vh', paddingTop: '0.1px' }}>
      <Box sx={{ backgroundColor: 'white' }}>
        <Box sx={{ backgroundColor: '#f0f0f0', paddingTop: 0, paddingBottom: 5, paddingLeft: leftPadding, paddingRight: rightPadding }}>
        <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: raidusBorder }}>
          <DataGridPro
            getRowId={(row) => row.id}
            editMode="row"
            pagination
            paginationPageSize={100}
            disableColumnSelector
            disableDensitySelector
            columns={columns}
            rows={rows}
            rowHeight={38}
            disableRowSelectionOnClick
            slots={{ toolbar: SectorsToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                handleSectorCreation: handleSectorCreation
              },
            }}
            onProcessRowUpdateError={(error) => {
              if (ApplicationError.is(error, 'invalid_data')) {
                alert("Les données saisies sont invalides.");
              } else {
                console.error(error);
                alert("Une erreur s'est produite lors de la sauvegarde des données.");
              }
              setUpdating(false);
            }}
            processRowUpdate={async (updatedRow, originalRow) => {
              if (!updatedRow.name) {
                throw new ApplicationError({error: 'invalid_data'});
              };
              setUpdating(true);
              const sector_id = updatedRow.id;
              const payload = {
                ...updatedRow,
              };
              const newSector = await handleSectorUpdate(sector_id, payload);
              return newSector;
            }}
          />
          </Box>
        </Box>
      </Box>
    </div>
  );
}