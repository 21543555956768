import axios from "axios";

import { isTokenExpired } from "../utils/jwt";
import { serializeDate, serializeTime, serializeDateTime, deserializeDate, deserializeTime, deserializeDateTime } from '../utils/dates'

const BASE_URL = process.env.REACT_APP_BASE_URL;


export class ApplicationError extends Error {
  data = null;

  static parse(error, ...args) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 400) {
        return new this(error.response.data, ...args);
      } else if (error.response?.status === 404) {
        return new this({error: 'not_found'}, ...args);
      }
    }
    return error;
  }

  static is(error, /*optional*/name) {
    if (name && !Array.isArray(name)) {
      name = [name];
    }
    return (error instanceof this) && (!name || (name.indexOf(error.data?.error) >= 0));
  }

  constructor(data, ...args) {
    super(...args);
    this.data = data;
  }
}


async function getToken() {
  let token = localStorage.getItem('token')
  if (!token) {
    throw new Error('Token not found')
  }

  if (isTokenExpired(token)) {
    await refreshToken()
    token = localStorage.getItem('token')
  }

  if (isTokenExpired(token)) {
    await refreshToken()
    token = localStorage.getItem('token')
  }

  return token
}

function getAuthHeaders(token) {
  return {
    'Authorization': `Bearer ${token}` // Add the JWT token as a Bearer token
  }
}

function getAxiosConfig(headers) {
  return {
    headers
  }
}

export async function fetchUsers(all) {
  const token = await getToken()
  const headers = getAuthHeaders(token)
  const config = getAxiosConfig(headers)
  const response = await axios.get(BASE_URL + '/users?all=' + (all ? 'true' : 'false'), config);
  const data = response.data;
  return data
}

const deserializeTimesheet = (data) => {
  const timesheet = {...data};

  timesheet.start = deserializeDate(timesheet.start);
  timesheet.end = deserializeDate(timesheet.end);

  timesheet.activities = timesheet.activities?.map((activity) => {
    activity = {...activity};
    activity.date = deserializeDate(activity.date);
    activity.start = deserializeTime(activity.start, activity.date);
    activity.end = deserializeTime(activity.end, activity.date);
    activity.start2 = deserializeTime(activity.start2, activity.date);
    activity.end2 = deserializeTime(activity.end2, activity.date);
    activity.start3 = deserializeTime(activity.start3, activity.date);
    activity.end3 = deserializeTime(activity.end3, activity.date);
    return activity;
  }) ?? null;

  timesheet.activities?.sort((activity1, activity2) => {
    if (activity1.date < activity2.date) {
      return -1;
    } else if (activity1.date > activity2.date) {
      return 1;
    } else {
      return 0;
    }
  });

  timesheet.time_allocations?.sort((allocation1, allocation2) => {
    const key1 = `${allocation1.shift}|${allocation1.contract_label}`;
    const key2 = `${allocation2.shift}|${allocation2.contract_label}`;
    if (key1 < key2) {
      return -1;
    } else if (key1 > key2) {
      return 1;
    } else {
      return 0;
    }
  });

  return timesheet;
}

export async function fetchTimesheets() {
  const token = await getToken()
  const headers = getAuthHeaders(token)
  const config = getAxiosConfig(headers)

  const response = await axios.get(BASE_URL + '/timesheets', config);
  const data = response.data;

  const timesheets = data.map((record) => deserializeTimesheet(record));

  return timesheets;
}

export async function fetchTimesheet(report_id) {
  try {
    const token = await getToken()
    const headers = getAuthHeaders(token)
    const config = getAxiosConfig(headers)

    const response = await axios.get(BASE_URL + '/timesheets/' + report_id, config);
    const data = response.data;

    if (!data) {
      return null;
    }

    const timesheet = deserializeTimesheet(data);

    return timesheet;
    
  } catch(error) {
    throw ApplicationError.parse(error);
  }
}

export async function fetchCurrentTimesheet() {
  const token = await getToken()
  const headers = getAuthHeaders(token)
  const config = getAxiosConfig(headers)

  const response = await axios.get(BASE_URL + '/timesheets/current', config);
  const data = response.data;

  if (!data) {
    return null;
  }

  const timesheet = deserializeTimesheet(data);

  return timesheet;
}

export async function PostSignIn(payload) {
  try {
    const response = await axios.post(BASE_URL + '/auth/login', payload);
    const responseData = response.data;
    return responseData;
  } catch(error) {
    return null;
  }
}

async function refreshToken() {
  const refresh = localStorage.getItem('refresh');

  if (isTokenExpired(refresh)) {
    localStorage.clear();
    window.location.href = '/';
  }

  const headers = getAuthHeaders(refresh)
  const config = getAxiosConfig(headers)

  const response = await axios.get(BASE_URL + '/auth/refresh', config);
  const token = response.data.access_token;
  localStorage.setItem('token', token);
}

export async function fetchUserProfile() {
  try {
    const token = await getToken()
    const headers = getAuthHeaders(token)
    const config = getAxiosConfig(headers)
    const response = await axios.get(BASE_URL + '/auth/profile', config);
    const responseData = response.data;
    return responseData;
  } catch(error) {
    return null;
  }
}

export async function UpdateTimesheet(timesheet_id, timesheet) {
  try {
    const token = await getToken()
    const headers = getAuthHeaders(token)
    const config = getAxiosConfig(headers)

    const formData = new FormData();
    
    timesheet = {...timesheet};
    timesheet.start = serializeDate(timesheet.start);
    timesheet.end = serializeDate(timesheet.end);

    timesheet.activities = timesheet.activities?.map((activity) => {
      activity = {...activity};
      activity.date = serializeDate(activity.date);
      activity.start = serializeTime(activity.start);
      activity.end = serializeTime(activity.end);
      activity.start2 = serializeTime(activity.start2);
      activity.end2 = serializeTime(activity.end2);
      activity.start3 = serializeTime(activity.start3);
      activity.end3 = serializeTime(activity.end3);
      return activity;
    }) ?? null;

    const files = timesheet.expenses?.filter(item => item instanceof File) ?? [];
    timesheet.expenses = timesheet.expenses?.filter(item => !(item instanceof File)) ?? [];

    formData.append('json', JSON.stringify(timesheet));

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    const response = await axios.put(BASE_URL + '/timesheets/' + timesheet_id, formData, config);
    const responseData = response.data;

    const updated = deserializeTimesheet(responseData);

    return updated;
  } catch(error) {
    throw ApplicationError.parse(error);
  }
}

export async function UpdateTimesheetStatus(timesheet_id, payload) {
  try {
    const token = await getToken()
    const headers = getAuthHeaders(token)
    const config = getAxiosConfig(headers)
    const response = await axios.patch(BASE_URL + '/timesheets/' + timesheet_id, payload, config);
    const responseData = response.data;
    const timesheet = deserializeTimesheet(responseData);
    return timesheet;
  } catch(error) {
    throw ApplicationError.parse(error);
  }
}

export async function fetchTimesheetSummary(report_id) {
  const token = await getToken()
  const headers = getAuthHeaders(token)
  const config = getAxiosConfig(headers)
  const response = await axios.get(BASE_URL + '/timesheets/' + report_id + '/summary', config);
  const data = response.data;
  return data;
}

export async function UpdateUserActive(user_id, payload) {
  try {
    const token = await getToken()
    const headers = getAuthHeaders(token)
    const config = getAxiosConfig(headers)
    const response = await axios.patch(BASE_URL + '/users/' + user_id, payload, config);
    const responseData = response.data;
    return responseData;
  } catch(error) {
    throw ApplicationError.parse(error)
  }
}

export async function FetchUser(user_id) {
  try {
    const token = await getToken()
    const headers = getAuthHeaders(token)
    const config = getAxiosConfig(headers)
    const response = await axios.get(BASE_URL + '/users/' + user_id, config);
    const responseData = response.data;
    return responseData;
  } catch(error) {
    throw ApplicationError.parse(error)
  }
}

export async function UpdateUser(user_id, payload) {
  try {
    const token = await getToken()
    const headers = getAuthHeaders(token)
    const config = getAxiosConfig(headers)
    const response = await axios.put(BASE_URL + '/users/' + user_id, payload, config);
    const responseData = response.data;
    return responseData;
  } catch(error) {
    throw ApplicationError.parse(error)
  }
}

export async function CreateUser(payload) {
  try {
    const token = await getToken()
    const headers = getAuthHeaders(token)
    const config = getAxiosConfig(headers)
    const response = await axios.post(BASE_URL + '/users', payload, config);
    const responseData = response.data;
    return responseData;
  } catch(error) {
    throw ApplicationError.parse(error);
  }
}

export async function DeleteUser(user_id) {
  const token = await getToken()
  const headers = getAuthHeaders(token)
  const config = getAxiosConfig(headers)
  await axios.delete(BASE_URL + '/users/' + user_id, config);
}

export async function fetchTimesheetImport() {
  const token = await getToken()
  const headers = getAuthHeaders(token)
  const config = getAxiosConfig(headers)
  config['responseType'] = 'blob'
  const response = await axios.get(BASE_URL + '/timesheets/import', config);
  const filename = response.headers.get('x-filename')
  const data = response.data;
  return { data, filename };
}

export async function ChangeAllTimesheetsStatus() {
  const token = await getToken()
  const headers = getAuthHeaders(token)
  const config = getAxiosConfig(headers)
  await axios.patch(BASE_URL + '/timesheets/status?from_status=En%20cours&to_status=Complété', {}, config);
}

export async function FetchContracts(all) {
  const token = await getToken()
  const headers = getAuthHeaders(token)
  const config = getAxiosConfig(headers)
  const response = await axios.get(BASE_URL + '/contracts?all=' + (all ? 'true' : 'false'), config);
  const data = response.data;
  return data;
}

export async function UpdateContract(contract_id, payload) {
  try {
    const token = await getToken()
    const headers = getAuthHeaders(token)
    const config = getAxiosConfig(headers)
    const response = await axios.put(BASE_URL + '/contracts/' + contract_id, payload, config);
    const responseData = response.data;
    return responseData;
  } catch(error) {
    throw ApplicationError.parse(error);
  }
}

export async function CreateContract(payload) {
  const token = await getToken();
  const headers = getAuthHeaders(token);
  const config = getAxiosConfig(headers);
  const response = await axios.post(BASE_URL + '/contracts', payload, config);
  const responseData = response.data;
  return responseData;
}

export async function DeleteContract(contract_id) {
  const token = await getToken()
  const headers = getAuthHeaders(token)
  const config = getAxiosConfig(headers)

  await axios.delete(BASE_URL + '/contracts/' + contract_id, config);
}

export async function FetchEquips(all) {
  const token = await getToken()
  const headers = getAuthHeaders(token)
  const config = getAxiosConfig(headers)
  const response = await axios.get(BASE_URL + '/equipments?all=' + (all ? 'true' : 'false'), config);
  const data = response.data;
  return data;
}

export async function UpdateEquip(equip_id, payload) {
  try {
    const token = await getToken()
    const headers = getAuthHeaders(token)
    const config = getAxiosConfig(headers)
    const response = await axios.put(BASE_URL + '/equipments/' + equip_id, payload, config);
    const responseData = response.data;
    return responseData;
  } catch(error) {
    throw ApplicationError.parse(error);
  }
}

export async function DeleteEquip(equip_id) {
  const token = await getToken()
  const headers = getAuthHeaders(token)
  const config = getAxiosConfig(headers)
  await axios.delete(BASE_URL + '/equipments/' + equip_id, config);
}

export async function CreateEquip(payload) {
  const token = await getToken();
  const headers = getAuthHeaders(token);
  const config = getAxiosConfig(headers);
  const response = await axios.post(BASE_URL + '/equipments', payload, config);
  const responseData = response.data;
  return responseData;
}

export async function FetchSectors(all) {
  const token = await getToken()
  const headers = getAuthHeaders(token)
  const config = getAxiosConfig(headers)
  const response = await axios.get(BASE_URL + '/sectors?all=' + (all ? 'true' : 'false'), config);
  const data = response.data;
  return data;
}

export async function UpdateSector(sector_id, payload) {
  try {
    const token = await getToken()
    const headers = getAuthHeaders(token)
    const config = getAxiosConfig(headers)
    const response = await axios.put(BASE_URL + '/sectors/' + sector_id, payload, config);
    const responseData = response.data;
    return responseData;
  } catch(error) {
    throw ApplicationError.parse(error);
  }
}

export async function DeleteSector(sector_id) {
  const token = await getToken()
  const headers = getAuthHeaders(token)
  const config = getAxiosConfig(headers)
  await axios.delete(BASE_URL + '/sectors/' + sector_id, config);
}

export async function CreateSector(payload) {
  const token = await getToken();
  const headers = getAuthHeaders(token);
  const config = getAxiosConfig(headers);
  const response = await axios.post(BASE_URL + '/sectors', payload, config);
  const responseData = response.data;
  return responseData;
}

const serializeNotification = (data) => {
  const notification = {...data};
  if ('expire_at' in notification) {
    notification.expire_at = serializeDateTime(notification.expire_at);
  }
  return notification;
}

const deserializeNotification = (data) => {
  const notification = {...data};
  notification.expire_at = deserializeDateTime(notification.expire_at);
  return notification;
}

export async function FetchNotifications(mode) {
  const token = await getToken()
  const headers = getAuthHeaders(token)
  const config = getAxiosConfig(headers)
  const response = await axios.get(`${BASE_URL}/notifications?mode=${mode}`, config);
  const data = response.data;
  const notifications = data.map((notification) => deserializeNotification(notification));
  return notifications;
}

export async function FetchNotification(id) {
  const token = await getToken()
  const headers = getAuthHeaders(token)
  const config = getAxiosConfig(headers)
  const response = await axios.get(`${BASE_URL}/notifications/${id}`, config);
  const data = response.data;
  if (!data) {
    return null;
  }
  const notification = deserializeNotification(data);
  return notification;
}

export async function UpdateNotification(notification_id, payload) {
  try {
    const notification = serializeNotification(payload)
    const token = await getToken()
    const headers = getAuthHeaders(token)
    const config = getAxiosConfig(headers)
    const response = await axios.put(`${BASE_URL}/notifications/${notification_id}`, notification, config);
    const data = response.data;
    const newNotification = deserializeNotification(data)
    return newNotification;
  } catch(error) {
    throw ApplicationError.parse(error);
  }
}

export async function DeleteNotification(notification_id) {
  const token = await getToken()
  const headers = getAuthHeaders(token)
  const config = getAxiosConfig(headers)
  await axios.delete(`${BASE_URL}/notifications/${notification_id}`, config);
}

export async function CreateNotification(payload) {
  const token = await getToken();
  const headers = getAuthHeaders(token);
  const config = getAxiosConfig(headers);
  const response = await axios.post(`${BASE_URL}/notifications`, payload, config);
  const data = response.data;
  const notification = deserializeNotification(data)
  return notification;
}

export async function FetchNextNotification() {
  const token = await getToken()
  const headers = getAuthHeaders(token)
  const config = getAxiosConfig(headers)
  const response = await axios.get(`${BASE_URL}/notifications/next`, config);
  const data = response.data;
  if (!data) {
    return null;
  }
  const notification = deserializeNotification(data);
  return notification;
}
