import { useState, useEffect } from "react";
import TimesheetsList from "../components/timesheet_list/timesheetslist";
import PrimaryAppBar from "../components/layout/appbar";
import { fetchTimesheets } from "../services/fetch";
import { useAuthentication } from '../components/use/login';
import { useErrorBoundary } from "react-error-boundary";

export function TimesheetsPage() {
  const [authentication] = useAuthentication();
  const [reports, setReports] = useState(null);
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    fetchTimesheets()
      .then((response) => {
        setReports(response);
      })
      .catch((error) => {
        showBoundary(error);
      });
  }, [authentication, showBoundary]);

  return (
    <div>
        <PrimaryAppBar page='timesheets'></PrimaryAppBar>
        <TimesheetsList reports={reports}></TimesheetsList>
    </div>
  );
};
