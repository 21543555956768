import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { 
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarContainer, 
  GridToolbarQuickFilter 
} from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useErrorBoundary } from 'react-error-boundary';

import { ApplicationError, FetchContracts } from '../../../services/fetch';

export default function CustomToolbar({onUserCreation}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [role, setRole] = useState("");
  const [sector, setSector] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [position, setPosition] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [defaultContract, setDefaultContract] = useState(null);
  const [contracts, setContracts] = useState([]);
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    FetchContracts(false)
      .then((contracts) => {
        setContracts(contracts);
      })
      .catch((error) => {
        showBoundary(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (openDialog) {
      setRole("");
      setSector("");
      setName("");
      setNumber("");
      setPosition("");
      setLogin("");
      setPassword("");
      setDefaultContract(null);
    };
  }, [openDialog]);

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleSectorChange = (event) => {
    setSector(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleNumberChange = (event) => {
    setNumber(event.target.value);
  };

  const handlePositionChange = (event) => {
    setPosition(event.target.value);
  };

  const handleLoginChange = (event) => {
    setLogin(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleDefaultContractChange = (event, newValue) => {
    setDefaultContract(newValue);
  }

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const payload = {
        name: name,
        number: number,
        position: position,
        sector: sector,
        role: role,
        login: login,
        password: password,
        default_contract_id: defaultContract ? defaultContract.id : null
      }

      await onUserCreation(payload);

      setOpenDialog(false);

    } catch (error) {
      if (ApplicationError.is(error, 'duplicated_login')) {
        // TODO: Mettre l'erreur en rouge au dessus ou en-dessous du champs erroné.
        alert("Ce nom d'utilisateur est déjà existant.");
      } else {
        showBoundary(error);
      }
    };

    return false;
  };

  return (
    <>
      <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <GridToolbarFilterButton />
          <Box ml={1}> {/* Adjust the margin as needed */}
              <GridToolbarExport />
          </Box>
          <Box ml={1}> {/* Adjust the margin as needed */}
            <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon/>}
            onClick={handleOpenDialog}
            size='small'
            sx={{ marginLeft: '8px',
                  "& .MuiButton-startIcon": {
                      marginRight: 'auto',
                      marginLeft: 'auto',
                  }
            }}
            />
          </Box>
        </Box>
        <Box>
          <GridToolbarQuickFilter />
        </Box>
      </GridToolbarContainer>
      <Dialog 
          open={openDialog} 
          onClose={handleCloseDialog} 
          PaperProps={{
              component: 'form',
              onSubmit: handleSubmit
          }}
      >
        <DialogTitle>Créer un nouvel utilisateur</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Pour inscrire un utilisateur sur Tempo, veuillez entrer les informations ci-dessous.
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                value={name}
                onChange={handleNameChange}
                label="Nom complet"
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                margin="dense"
                id="number"
                value={number}
                onChange={handleNumberChange}
                label="# Employé"
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                margin="dense"
                id="position"
                value={position}
                onChange={handlePositionChange}
                label="Poste/Métier"
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="lblSector">Secteur</InputLabel>
              <Select
                id="sector"
                labelId="lblSector"
                value={sector}
                required
                onChange={handleSectorChange}
                fullWidth
              >
                <MenuItem value='Bureau'>Bureau</MenuItem>
                <MenuItem value='Carrières/Sablières'>Carrières/Sablières</MenuItem>
                <MenuItem value='Hors construction'>Hors construction</MenuItem>
                <MenuItem value='Laboratoire'>Laboratoire</MenuItem>
                <MenuItem value='Signalisation-04'>Signalisation-04</MenuItem>
                <MenuItem value='Signalisation-06'>Signalisation-06</MenuItem>
                <MenuItem value='Tech-Chantier'>Tech-Chantier</MenuItem>
                <MenuItem value='Tech-Chantier'>Sartigan - Bureau</MenuItem>
                <MenuItem value='Tech-Chantier'>Sartigan - Usine</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="lblRole">Rôle</InputLabel>
              <Select
                id="role"
                labelId="lblRole"
                value={role}
                required
                onChange={handleRoleChange}
                fullWidth
              >
                <MenuItem value='admin'>Administrateur</MenuItem>
                <MenuItem value='foreman'>Contremaître</MenuItem>
                <MenuItem value='employee'>Employé</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                margin="dense"
                id="login"
                value={login}
                onChange={handleLoginChange}
                label="Nom d'utilisateur"
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                margin="dense"
                id="password"
                value={password}
                onChange={handlePasswordChange}
                label="Mot de passe"
                type="password"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="lblDefaultContract">Contrat par défaut</InputLabel>
              <Autocomplete
                options={contracts}
                style={{ width: '100%', minWidth: '300px' }} // Set the width of the Autocomplete to 100%
                value={defaultContract}
                onChange={handleDefaultContractChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Annuler
          </Button>
          <Button type="submit" color="primary">
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
