import { useState, useEffect } from "react";
import { useErrorBoundary } from "react-error-boundary";

import UserList from "../components/user_list/mobile/user_list";
import { fetchUsers, UpdateUserActive, UpdateUser, CreateUser, DeleteUser, ApplicationError } from "../services/fetch";
import PrimaryAppBar from "../components/layout/appbar";

export function UserListPage() {
  const [users, setUsers] = useState([]);
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    fetchUsers(true)
      .then((response) => {
        setUsers(response);
      })
      .catch((error) => {
        showBoundary(error);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleUserActiveUpdate = async (user_id, data) => {
    try {
      const updated = await UpdateUserActive(user_id, data);
      const newUsers = users.map((user) => (user.id === user_id ? updated : user));
      setUsers(newUsers);
      return updated
    } catch(error) {
      if (ApplicationError.is(error, ['outdated_record', 'not_found'])) {
        // TODO: Modal Dialog
        alert("Vous travaillez présentement sur une ancienne version. Svp veuillez actualiser la page.");
      } else {
        showBoundary(error);
      }
      throw error;
    }
  };

  const handleUserUpdate = async (user_id, data) => {
    try {
      const updated = await UpdateUser(user_id, data)
      const newUsers = users.map((user) => (user.id === user_id ? updated : user));
      setUsers(newUsers);
      return updated
    } catch(error) {
      if (ApplicationError.is(error, ['outdated_record', 'not_found'])) {
        // TODO: Modal Dialog
        alert("Vous travaillez présentement sur une ancienne version. Svp veuillez actualiser la page.");
      } else {
        showBoundary(error);
      }
      throw error;
    }
  };

  const handleUserCreation = async (data) => {
    try {
      const newUser = await CreateUser(data)
      const newUsers = [newUser, ...users]
      setUsers(newUsers);
      return newUser
    } catch(error) {
      if (ApplicationError.is(error)) {
        throw error;
      } else {
        showBoundary(error);
      }
    }
  };

  const handleUserDeletion = async (user_id) => {
    try {
      await DeleteUser(user_id);
      const newUsers = users.filter((user) => user.id !== user_id);
      setUsers(newUsers);
    } catch(error) {
      showBoundary(error);
    }
  };

  return (
    <div>
      <PrimaryAppBar page='users'></PrimaryAppBar>
      <UserList users={users} onActiveChange={handleUserActiveUpdate} onUserUpdate={handleUserUpdate} onUserCreation={handleUserCreation} onUserDeletion={handleUserDeletion}></UserList>
    </div>
  );
}
