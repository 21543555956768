import jwt_decode from 'jwt-decode'



export function isConnected() {

    const token = localStorage.getItem('token');

      if (!token) {
        return false;
      }

      return true;

}

export const isTokenExpired = (token) => {
  const decodedToken = jwt_decode(token);
  const currentTime = Math.floor(Date.now() / 1000)
  const expireTime = decodedToken.exp - 10
  return expireTime < currentTime;
}