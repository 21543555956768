import { useState, useEffect } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { FetchContracts, UpdateContract, CreateContract, DeleteContract, ApplicationError } from "../services/fetch";

import ContractList from "../components/contracts/contracts";
import PrimaryAppBar from "../components/layout/appbar";


export function ContractListPage() {
  const [contracts, setContracts] = useState([]);
  const { showBoundary } = useErrorBoundary();
  
  useEffect(() => {
    FetchContracts(true)
      .then((response) => {
        setContracts(response);
      })
      .catch((error) => {
        showBoundary(error);
      });
  }, [showBoundary]);

  const handleContractUpdate = async (contract_id, data) => {
    try {
      const newContract = await UpdateContract(contract_id, data);
      const newContracts = contracts.map((contract) => (contract.id === contract_id ? newContract : contract));
      setContracts(newContracts);
      return newContract
    } catch(error) {
      if (ApplicationError.is(error, ['outdated_record', 'not_found'])) {
        // TODO: Modal
        alert("Vous travaillez présentement sur une ancienne version. Svp veuillez actualiser la page.");
      } else {
        showBoundary(error);
      }
      throw error;
    }
  };

  const handleContractCreation = async (data) => {
    try {
      const newContract = await CreateContract(data)
      const newContracts = [newContract, ...contracts];
      setContracts(newContracts);
      return newContract
    } catch(error) {
      showBoundary(error);
    }
  };

  const handleContractDeletion = async (contract_id) => {
      try {
        await DeleteContract(contract_id);
        const newContracts = contracts.filter((contract) => contract.id !== contract_id);
        setContracts(newContracts);
        return newContracts
      } catch(error) {
        showBoundary(error);
      }
    };


  return (
    <div>
      <PrimaryAppBar page='contracts'></PrimaryAppBar>
      <ContractList contracts={contracts} handleContractUpdate={handleContractUpdate} handleContractCreation={handleContractCreation} handleContractDeletion={handleContractDeletion}></ContractList>
    </div>
  );
}