import dayjs from 'dayjs'

export const reportInvalidDate = (value) => dayjs(value).isValid() ? value : console.error(`Invalid date value: ${value}`) || value

export const TIMEZONE = 'America/Toronto'
export const setTimezone = (value) => value ? reportInvalidDate(dayjs(value)).tz(TIMEZONE) : value

export const serializeDate = (value) => value ? reportInvalidDate(dayjs(value)).format('MM/DD/YYYY') : null
export const serializeDateTime = (value) => value ? reportInvalidDate(dayjs(value)).toISOString() : null
export const serializeTime = (value) => value ? reportInvalidDate(dayjs(value)).tz('UTC').format('HH:mm:ss') : null


export const deserializeDate = (value) => value ? reportInvalidDate(dayjs(value)) : null
export const deserializeDateTime = (value) => value ? reportInvalidDate(dayjs(value)) : null
export const deserializeTime = (value, date) => value ? reportInvalidDate(dayjs.tz((date ? dayjs(date).format('YYYY-MM-DD') + ' ' : '') + value, (date ? 'YYYY-MM-DD HH:mm:ss' : 'HH:mm:ss'), 'UTC')).tz() : null

export const formatDate = (value) => value ? reportInvalidDate(dayjs(value)).format('YYYY-MM-DD') : ''
export const formatDateTime = (value) => value ? reportInvalidDate(dayjs(value)).format('YYYY-MM-DD HH:mm:ss') : ''
export const formatTime = (value) => value ? reportInvalidDate(dayjs(value)).format('HH:mm:ss') : ''
export const formatTimeWithoutSeconds = (value) => value ? reportInvalidDate(dayjs(value)).format('HH:mm') : ''
