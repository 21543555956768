import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import SafetyDividerOutlinedIcon from '@mui/icons-material/SafetyDividerOutlined';
import Typography from '@mui/material/Typography';
import { InboxOutlined } from '@mui/icons-material';

export default function LeftMenu({role, setOpen}) {
  const navigate = useNavigate();

  const toggleOpen = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
  
    setOpen(open);
  };

  const handleItemClick = (url) => (event) => {
    setOpen(false);
    navigate(url);
  }

  return (
    <Box sx={{ backgroundColor: '#343434' }}>
      <Box
        sx={{ width: 250, marginTop: 6, backgroundColor: '#343434' }}
        role="presentation"
        onClick={toggleOpen(false)}
        onKeyDown={toggleOpen(false)}
      >
        <Divider sx={{ backgroundColor: '#696969'  }}/>
        <List>
            <ListItem disablePadding>
              <ListItemButton onClick={handleItemClick('/timesheets')}>
                <ListItemIcon sx={{ color: '#E0E0E0' }}>
                  <AccessTimeIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body1" color="white" sx={{ fontFamily: 'Arial, sans-serif' }}>
                      Feuilles de temps
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
            { (role === 'admin') ? (
              <>
                <ListItem disablePadding>
                  <ListItemButton onClick={handleItemClick('/users')}>
                    <ListItemIcon sx={{ color: '#E0E0E0' }}>
                      <PeopleOutlineIcon />
                    </ListItemIcon>
                    <ListItemText
                    primary={
                      <Typography variant="body1" color="white" sx={{ fontFamily: 'Arial, sans-serif' }}>
                        Utilisateurs
                      </Typography>
                    }
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={handleItemClick('/sectors')}>
                    <ListItemIcon sx={{ color: '#E0E0E0' }}>
                      <SafetyDividerOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                    primary={
                      <Typography variant="body1" color="white" sx={{ fontFamily: 'Arial, sans-serif' }}>
                        Secteurs
                      </Typography>
                    }
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={handleItemClick('/equips')}>
                    <ListItemIcon sx={{ color: '#E0E0E0' }}>
                      <HandymanOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                    primary={
                      <Typography variant="body1" color="white" sx={{ fontFamily: 'Arial, sans-serif' }}>
                        Équipements
                      </Typography>
                    }
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={handleItemClick('/contracts')}>
                    <ListItemIcon sx={{ color: '#E0E0E0' }}>
                      <WorkOutlineIcon />
                    </ListItemIcon>
                    <ListItemText
                    primary={
                      <Typography variant="body1" color="white" sx={{ fontFamily: 'Arial, sans-serif' }}>
                        Contrats
                      </Typography>
                    }
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={handleItemClick(`/notifications?mode=edit`)}>
                    <ListItemIcon sx={{ color: '#E0E0E0' }}>
                      <InboxOutlined />
                    </ListItemIcon>
                    <ListItemText
                    primary={
                      <Typography variant="body1" color="white" sx={{ fontFamily: 'Arial, sans-serif' }}>
                        Notifications
                      </Typography>
                    }
                    />
                  </ListItemButton>
                </ListItem>
              </>
            ) : (
              <>
                <ListItem disablePadding>
                  <ListItemButton onClick={handleItemClick(`/notifications?mode=read`)}>
                    <ListItemIcon sx={{ color: '#E0E0E0' }}>
                      <InboxOutlined />
                    </ListItemIcon>
                    <ListItemText
                    primary={
                      <Typography variant="body1" color="white" sx={{ fontFamily: 'Arial, sans-serif' }}>
                        Notifications
                      </Typography>
                    }
                    />
                  </ListItemButton>
                </ListItem>
              </>
            )}
        </List>
        <Divider sx={{ backgroundColor: '#696969' }} />
      </Box>
    </Box>
  );
};