import { useSearchParams } from "react-router-dom";

import NotificationsList from "../components/notifications/notifications";
import PrimaryAppBar from "../components/layout/appbar";


export function NotificationsListPage() {
  const [params] = useSearchParams()

  return (
    <div>
      <PrimaryAppBar page='notifications'></PrimaryAppBar>
      <NotificationsList mode={params.get('mode') || 'read'}></NotificationsList>
    </div>
  );
}