import { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useErrorBoundary } from "react-error-boundary";

import logo from '../../assets/cppi-logo-white.png'
import { PostSignIn } from '../../services/fetch';
import LoadingMobile from '../timesheet/mobile/loading';
import { useAuthentication } from '../use/login'

function Copyright(props) {
  return (
    <Typography variant="body2" color="#B0B0B0" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.cppinc.ca/">
        Construction & Pavage Portneuf Inc.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export function SignIn() {

  // eslint-disable-next-line no-unused-vars
  const [authentication, setAuthentication] = useAuthentication();
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);

  const { showBoundary } = useErrorBoundary();

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    try {
      setLoad(true);
      event.preventDefault();
      const data = new FormData(event.currentTarget);

      const payload = {
        username: data.get('email'),
        password: data.get('password')
      }

      const response = await PostSignIn(payload);

      if (response) {
        setAuthentication({
          token: response.access_token, 
          refresh: response.refresh_token,
        });
  
        navigate('/timesheets')
      } else {
        alert("Nom d'utilisateur ou mot de passe invalide. Veuillez ré-essayer.")
      }

      setLoad(false);
      setOpen(false);

    } catch (error) {
      setLoad(false);
      setOpen(true);
      showBoundary(error);
    }
  };

  return (
    <Box sx={{ backgroundColor: '#343434', height: '100vh' }}>
    {load && <LoadingMobile></LoadingMobile>}
    <Collapse in={open}>
        <Alert
        severity='error'
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          La tentative de connexion a échoué - <strong>Veuillez réessayer</strong>
        </Alert>
    </Collapse>
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <img src={logo} alt="logo" width="250" />

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Nom d'utilisateur"
              name="email"
              autoComplete="email"
              autoFocus
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#5C5C5C', // Set background color to light grey
                  '& fieldset': {
                    borderColor: '#9D9D9D', // Set border color to white by default
                    color: '#9D9D9D', // Set text color to white
                  },
                  '&:hover fieldset': {
                    borderColor: '#9D9D9D', // Set border color to warning color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#E0E0E0', // Set border color to warning color when focused
                  },
                },
                '& .MuiFormLabel-root': {
                  color: '#E0E0E0', // Set label color to white
                  '&.Mui-focused': {
                    color: '#E0E0E0', // Set label color to white when focused
                },
                },
                '& .MuiInputBase-input': {
                  color: '#E0E0E0', // Set text color of input text
                }
              }}
            />
            <TextField
              margin="normal"
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              autoComplete="current-password"
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#5C5C5C', // Set background color to light grey
                  '& fieldset': {
                    borderColor: '#9D9D9D', // Set border color to white by default
                    color: '#9D9D9D', // Set text color to white
                  },
                  '&:hover fieldset': {
                    borderColor: '#9D9D9D', // Set border color to warning color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#E0E0E0', // Set border color to warning color when focused
                  },
                },
                '& .MuiFormLabel-root': {
                  color: '#E0E0E0', // Set label color to white
                  '&.Mui-focused': {
                    color: '#E0E0E0', // Set label color to white when focused
                },
                },
                '& .MuiInputBase-input': {
                  color: '#E0E0E0', // Set text color of input text
                }
              }}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              color='warning'
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Se connecter
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
    </Box>
  );
}