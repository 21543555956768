import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import ImageMobile from './image';
import Stack from '@mui/material/Stack';


export default function FileBox({editable, access, idx, handleDelete, expense}) {
  return (
    <Stack direction="row" spacing={2}>
    <Card
        sx={{
        display: 'flex',
        boxShadow: 'none', // Remove the shadow
        }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexGrow: 1 }}>
        <ImageMobile editable={editable} access={access} expense={expense} idx={idx} handleDelete={handleDelete} ></ImageMobile>
      </Box>
    </Card>
    </Stack>
  );
}