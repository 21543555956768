import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Chip from '@mui/material/Chip';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import CircularProgress from '@mui/material/CircularProgress';
import { useErrorBoundary } from "react-error-boundary";

import fetchDataAndGeneratePdf from '../../utils/jspdf';
import { dateTimeGetter, dateFormatter } from '../mui_utils'
import { deserializeDate } from '../../utils/dates';
import { getBigTotal } from '../../utils/compute'
import CustomToolbar from './customtoolbar'

function addToDicts(listOfDicts) {
  for (const dict of listOfDicts) {
    dict['total'] = getBigTotal(dict.activities || []).toFixed(2);
    dict['expense_number'] = dict.expenses?.length ?? 0;
  }
}

export default function TimesheetsList({ reports }) {

  const navigate = useNavigate();

  if (reports) {
    addToDicts(reports);
  }

  const handleClick = (params) => {
    if (params.field !== 'action') {
      navigate(`/timesheets/${params.row.id}`);
    }
  };

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.up("xs"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));

  const { showBoundary } = useErrorBoundary();

  const defaultNameRenderCell = (params) => (
    <Box>
      {params.row.user_name}
    </Box>
  );

  const defaultStatusRenderCell = (params) => {
    const status = params.value;
    let color;
    
    switch(status) {
      case 'En cours':
        color = 'success';
        break;
      case 'En traitement':
        color = 'warning';
        break;
      case 'Complété':
        color = 'primary';
        break;
      case 'Archivé':
        color = 'error';
        break;
      default:
        color = 'default';
    }
    
    return <Chip label={status} color={color} variant="outlined" />;
  }

  const xsNameRenderCell = (params) => (
      <Box>
        <Box>{params.row.user_name}</Box>
        <Box sx={{ fontSize: 12 }}>{deserializeDate(params.row.start).format('D MMM YYYY')}</Box>
      </Box>
  );

  const xsStatusRenderCell = (params) => {
    const status = params.value;
    let color;
    
    switch(status) {
      case 'En cours':
        color = 'success';
        break;
      case 'En traitement':
        color = 'warning';
        break;
      case 'Complété':
        color = 'primary';
        break;
      case 'Archivé':
        color = 'error';
        break;
      default:
        color = 'default';
    }
    
    return <Chip label={status} color={color} variant="outlined" size="small" sx={{ fontSize: 12 }} />;
  }

  const userNameCell = md ? defaultNameRenderCell : xsNameRenderCell;
  const statusRenderCell = md ? defaultStatusRenderCell : xsStatusRenderCell;

  let columnVisibility;
  let leftPadding = 0;
  let rightPadding = 0;
  let raidusBorder = 0;

  if (xl) {

    columnVisibility = {};
    leftPadding = 25
    rightPadding = 25
    raidusBorder = 0

  } else if (lg) {

    columnVisibility = {
      expense_number: false,
      sector: false
    };

  } else if (md) {

    columnVisibility = {
      expense_number: false,
      sector: false,
      action: false,
      user_number: false
    };

  } else if (sm) {

    columnVisibility = {
      expense_number: false,
      sector: false,
      action: false,
      start: false,
      end: false,
      approved: false,
      user_number: false
    };

  } else if (xs) {

    columnVisibility = {
      expense_number: false,
      sector: false,
      action: false,
      start: false,
      end: false,
      approved: false,
      user_number: false
    };

  } else {

    columnVisibility = {
      expense_number: false,
      sector: false,
      user_number: false
    };

  }

  let columns = [
    {
      field: 'user_number',
      headerName: '#',
      type: 'string',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <strong>
          {'#'}
        </strong>
      ),
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.user_number}
            size="small"
            style={{ marginTop: '5px' }}
          />
        );
      }
    },
    {
      field: 'user_name',
      headerName: 'Nom',
      type: 'string',
      flex: 1.25,
      renderHeader: () => (
        <strong>
          {'Nom'}
        </strong>
      ),
      renderCell: userNameCell
    },
    {
      field: 'start',
      headerName: 'Début',
      type: 'date',
      valueGetter: dateTimeGetter,
      valueFormatter: dateFormatter,
      flex: 1,
      renderHeader: () => (
        <strong>
          {'Début'}
        </strong>
      ),
    },
    {
      field: 'end',
      headerName: 'Fin',
      type: 'date',
      valueGetter: dateTimeGetter,
      valueFormatter: dateFormatter,
      flex: 1,
      renderHeader: () => (
        <strong>
          {'Fin'}
        </strong>
      ),
    },
    {
      field: 'status',
      headerName: 'Statut',
      type: 'singleSelect',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      valueOptions: [
        'En cours',
        'En traitement',
        'Complété',
        'Archivé'
      ],
      renderHeader: () => (
        <strong>
          {'Statut'}
        </strong>
      ),
      renderCell: statusRenderCell
    },
    {
      field: 'total',
      headerName: 'Total',
      type: 'number',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <strong>
          {'Total'}
        </strong>
      ),
    },
    {
      field: 'is_approved',
      headerName: 'Approuvé',
      type: 'boolean',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: () => (
        <strong>
          {'Approuvé'}
        </strong>
      ),
    },
    {
      field: 'sector',
      headerName: 'Secteur',
      type: 'string',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: () => (
        <strong>
          {'Secteur'}
        </strong>
      ),
    },
    {
      field: 'expense_number',
      headerName: 'Dépenses',
      type: 'number',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <strong>
          {'Dépenses'}
        </strong>
      ),
    },
    {
      field: 'action',
      headerName: 'PDF',
      type: 'actions',
      flex: 1,
      renderHeader: () => (
        <strong>
          {'PDF'}
        </strong>
      ),
      renderCell: (params) => {
        const handleClick = () => {
          fetchDataAndGeneratePdf(params.row.id)
            .catch((error) => {
              showBoundary(error);
            });
        };
  
        return (
          <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={handleClick}
            >
              <PictureAsPdfIcon sx={{ color: '#343434' }} />
            </IconButton>
        );
      }
    }
  ]

  return (
    <div style={{ backgroundColor: '#f0f0f0', minHeight: '100vh', paddingTop: '0.1px' }}>
      <Box sx={{ backgroundColor: 'white' }}>
        <Box sx={{ backgroundColor: '#f0f0f0', paddingTop: 0, paddingBottom: 5, paddingLeft: leftPadding, paddingRight: rightPadding }}>
          <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: raidusBorder }}>
            {!reports ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
              </Box>
            ) : (
            <DataGridPro
              getRowId={(row) => row.id}
              pagination
              paginationPageSize={100}
              disableColumnSelector
              disableDensitySelector
              columnVisibilityModel={columnVisibility}
              columns={columns}
              rows={reports}
              slots={{ toolbar: CustomToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              sx={{ borderRadius: raidusBorder }}
              onCellClick={handleClick}
            />

            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
}
