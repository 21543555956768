import { Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import FileBox from './file';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

function DropzoneMobile({ editable, report, access, handleUpdateReport }) {
  const [expenses, setExpenses] = useState(report.expenses);

  useEffect(() => {
    setExpenses(report.expenses);
  }, [report]);

  const handleExpensesChange = (event) => {
    setExpenses(() => {
      const newExpenses = [...event.target.files, ...expenses];

      const changes = { 
        expenses: newExpenses
      };
      handleUpdateReport(changes);

      return newExpenses;
    });
  };

  const handleExpenseDelete = (indexToRemove) => {
      setExpenses((prevExpenses) => {
          const newExpenses = prevExpenses.filter((_, index) => index !== indexToRemove);

          const changes = { 
            expenses: newExpenses
          };
          handleUpdateReport(changes);

          return newExpenses;
      });
  };

  return (
    <Box>
        <Typography sx={{ marginBottom: '1px' }}>
          <b>Factures</b>
        </Typography>
        <Typography sx={{ marginBottom: '10px', fontSize: 14 }}>
          (Fichiers JPG, JPEG et PNG seulement)
        </Typography>
        <div>
            <Box
              sx={{
                  width: '100%',
                  maxWidth: '100%',
                  color: 'white',
              }}
            >
              <Box sx={{ borderRadius: '6px', height: '100%', backgroundColor: '#EDEDED' }}>
                  <Button
                    component="label"
                    sx={{
                        p: 2,
                        border: '1px dashed grey',
                        borderRadius: 1,
                        width: '100%', // Button takes 100% width of its container
                    }}
                    startIcon={<CloudUploadIcon />}
                    disabled={!access || !editable}
                  >
                    Téléverser un fichier
                    <VisuallyHiddenInput type="file" onChange={handleExpensesChange} />
                  </Button>
              </Box>
            </Box>
        </div>
        <Box paddingTop={4}>
            {expenses.map((expense, index) => (
              <Box key={index}>
                <Box key={index} sx={{ width: '100%', marginBottom: '12px', position: 'relative' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FileBox editable={editable} access={access} expense={expense} idx={index} handleDelete={handleExpenseDelete}></FileBox> 
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ))}
        </Box>
    </Box>
  );
}

export default DropzoneMobile;
