import { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';

export default function TopMenu({role, onItemClick}) {
  const [sheetsAnchorEl, setSheetsAnchorEl] = useState(null);
  const [adminAnchorEl, setAdminAnchorEl] = useState(null);
  const navigate = useNavigate();
  const sheetsOpen = Boolean(sheetsAnchorEl);
  const adminOpen = Boolean(adminAnchorEl);

  const handleMenuClose = () => {
    setSheetsAnchorEl(null);
    setAdminAnchorEl(null);
  };

  const handleSheetsMenuOpen = (event) => {
    setSheetsAnchorEl(event.currentTarget);
  };

  const handleSheetsItemClick = (url) => () => {
    handleMenuClose();
    navigate(url);
  };

  const handleAdminMenuOpen = (event) => {
    setAdminAnchorEl(event.currentTarget);
  };

  const handleAdminItemClick = (url) => () => {
    handleMenuClose();
    navigate(url);
  };

  return (
    <div>
      <div style={{display: 'inline-block'}}>
        <Button
          id="topmenu-sheets-button"
          aria-controls={sheetsOpen ? 'topmenu-sheets-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={sheetsOpen ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleSheetsMenuOpen}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Feuilles
        </Button>
        <Menu
          id="topmenu-sheets-menu"
          MenuListProps={{
            'aria-labelledby': 'topmenu-sheets-button',
          }}
          anchorEl={sheetsAnchorEl}
          open={sheetsOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleSheetsItemClick('/timesheets')}>
            Temps
          </MenuItem>
        </Menu>
      </div>
      {(role === 'admin') ?
        (<div style={{display: 'inline-block', paddingLeft: '6px'}}>
          <Button
            id="topmenu-admin-button"
            aria-controls={adminOpen ? 'topmenu-admin-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={adminOpen ? 'true' : undefined}
            variant="contained"
            disableElevation
            onClick={handleAdminMenuOpen}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Administration
          </Button>
          <Menu
            id="topmenu-admin-menu"
            MenuListProps={{
              'aria-labelledby': 'topmenu-admin-button',
            }}
            anchorEl={adminAnchorEl}
            open={adminOpen}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleAdminItemClick('/users')}>
              Utilisateurs
            </MenuItem>
            <MenuItem onClick={handleAdminItemClick('/sectors')}>
              Secteurs
            </MenuItem>
            <MenuItem onClick={handleAdminItemClick('/equips')}>
              Équipements
            </MenuItem>
            <MenuItem onClick={handleAdminItemClick('/contracts')}>
              Contrats
            </MenuItem>
            <MenuItem onClick={handleAdminItemClick('/notifications?mode=edit')}>
              Notifications
            </MenuItem>
          </Menu>
        </div>) : null
      }
    </div>
  );
}