import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import { useErrorBoundary } from "react-error-boundary";

import MobileTimesheet from "../components/timesheet/mobile/timesheet";
import LoadingMobile from '../components/timesheet/mobile/loading';
import SuccessToast from '../components/timesheet/mobile/success';
import { fetchTimesheet, FetchContracts, UpdateTimesheet, fetchCurrentTimesheet, ApplicationError } from "../services/fetch";
import { newId, resetIds } from "../utils/random";

export function TimesheetPage() {
    const url_param = useParams();
    const [report, setReport] = useState(null);
    const [contracts, setContracts] = useState(null);
    const [changed, setChanged] = useState(false);
    const [load, setLoad] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const { showBoundary } = useErrorBoundary();

    const id = url_param['id']

    useEffect(() => {
      const fetch = async () => {
        if (id) {
          return await fetchTimesheet(id);
        }
        return await fetchCurrentTimesheet();
      }
      fetch()
        .then((timesheet) => {
          setReport(timesheet);
          if (timesheet && !timesheet.id) {
            timesheet.id = newId();
            timesheet.activities.forEach(activity => activity.id = newId());
            setChanged(true);
          }
        })
        .catch((error) => {
          if (ApplicationError.is(error, 'not_found')) {
            // TODO: Mdoal Dialog
            alert("Feuille de temps inexistante ou accès refusé.")
            setReport(null)
          } else {
            showBoundary(error);
          }
        });
    }, [id, showBoundary]);

    useEffect(() => {
      FetchContracts(false)
        .then((response) => {
          setContracts(response);
        })
        .catch((error) => {
          showBoundary(error);
        });
    }, [showBoundary]);

    const handleUpdateReport = (changes) => {
      const newReport = {...report, ...changes};
      setReport(newReport);
      setChanged(true);
    }

    const handleSaveReport = async () => {
      try {
        setLoad(true);
        const updated = await UpdateTimesheet(report.id, report)
        setReport(updated);
        resetIds();
        setLoad(false);
        setChanged(false);
        showSuccessMessage();
      } catch(error) {
        if (ApplicationError.is(error, ['outdated_record', 'not_found'])) {
          alert("Vous travaillez présentement sur une ancienne version. Svp veuillez actualiser la page.");
        } else {
          showBoundary(error);
        }
        setLoad(false);
        throw error;
      }
    }

    const showSuccessMessage = () => {
      setIsSuccessful(true);

      // Reset the success state after a certain period (e.g., 3 seconds)
      setTimeout(() => {
        setIsSuccessful(false);
      }, 3500);
    };

    if (!report) {
      return (
        <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItem: 'center'
            }}
          >
          <CircularProgress></CircularProgress>
        </Box>
      )
    } else {
      return (
        <div>
          {load && <LoadingMobile />}
          {isSuccessful && <SuccessToast />}
          <MobileTimesheet report={report} contracts={contracts} changed={changed} handleUpdateReport={handleUpdateReport} handleSaveReport={handleSaveReport} handleSetReport={setReport}></MobileTimesheet> 
        </div>
      );
    }
  }