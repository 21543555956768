import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useErrorBoundary } from 'react-error-boundary';
import { InboxOutlined } from '@mui/icons-material';

import fetchDataAndGeneratePdf from '../../../utils/jspdf';
import { FetchNextNotification, FetchNotification } from '../../../services/fetch';
import { NotificationDialog } from '../../notifications/notification';


export default function MoreMenu({report_id}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showInbox, setShowInbox] = useState(false);
  const [nextNotification, setNextNotification] = useState(null);
  const [openNotification, setOpenNotification] = useState(false);
  const { showBoundary } = useErrorBoundary();
  
  const open = Boolean(anchorEl);

  useEffect(() => {
    let id = null;
    const fetch = () => {
      if (!openNotification) {
        FetchNextNotification()
          .then((next) => {
            setNextNotification(next)
            setShowInbox(!!next);
            id = setTimeout(fetch, 1 * 60 * 1000);
          })
          .catch((error) => {
            showBoundary(error);
          })
      } else {
        id = setTimeout(fetch, 1 * 60 * 1000);
      }
    }
    fetch();
    return () => {
      clearTimeout(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openNotification])

  useEffect(() => {
    if (nextNotification && !openNotification) {
      const toggle = () => {
        setShowInbox(!showInbox)
      }
      const id = setTimeout(toggle, 500);
      return () => {
        clearTimeout(id);
      }
    } else {
      setShowInbox(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextNotification, showInbox, openNotification])

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePdfClick = () => {
    handleMenuClose();
    fetchDataAndGeneratePdf(report_id)
      .catch((error) => {
        showBoundary(error);
      });
  }

  const handleNotificationClick = () => {
    FetchNotification(nextNotification.id)
      .then((notification) => {
        setNextNotification(notification);
        setOpenNotification(true);
      })
      .catch((error) => {
        showBoundary(error);
      })
  }

  const handleCloseNotification = () => {
    setOpenNotification(false);
    setNextNotification(null);
  }

  return (<div>
    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
      <IconButton
        size="large"
        color="inherit"
        onClick={handlePdfClick}
      >
        <PictureAsPdfIcon />
      </IconButton>
      <IconButton
        size="large"
        color="inherit"
        onClick={handleNotificationClick}
      >
        <InboxOutlined style={{visibility: showInbox ? 'visible' : 'hidden'}} />
      </IconButton>
    </Box>
    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
      <IconButton
          size="large"
          aria-label="show more"
          aria-controls="primary-search-account-menu-mobile"
          aria-haspopup="true"
          onClick={handleMenuOpen}
          color="inherit"
      >
        <MoreIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id="primary-search-account-menu-mobile"
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handlePdfClick}>
          <IconButton
            size="large"
            color="inherit"
          >
            <PictureAsPdfIcon  />
          </IconButton>
          <p>Enregistrer en format PDF</p>
        </MenuItem>
      </Menu>
      <IconButton
        size="large"
        color="inherit"
        onClick={handleNotificationClick}
      >
        <InboxOutlined style={{visibility: showInbox ? 'visible' : 'hidden'}} />
      </IconButton>
    </Box>
    <NotificationDialog mode='read' open={openNotification} notification={nextNotification} handleClose={handleCloseNotification} />
  </div>);
}
