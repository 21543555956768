import { useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, Button } from '@mui/material';
import { useBlocker } from 'react-router';

export default function Blocker({onLeave}) {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => {
      if ((currentLocation.pathname !== nextLocation.pathname) && onLeave()) {
        return true;
      }
      return false;
    }
  );

  useEffect(() => {
    const handleUnload = (e) => {
      if (onLeave()) {
        e.preventDefault();
        return false;
      }
    };
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [onLeave]);

  const handleYes = () => {
    blocker.proceed();
  };

  const handleNo = () => {
    blocker.reset();
  };

  return (
    <Dialog open={blocker.state === 'blocked'}>
      <DialogTitle>
        Changements en cours
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Des changements sont en cours. Voulez-vous quiter ?
        </DialogContentText>
        <DialogActions>
          <Button onClick={handleYes} color="primary">
            Oui
          </Button>
          <Button onClick={handleNo} color="primary">
            Non
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
