import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useTheme } from "@mui/material/styles";
import { Autocomplete, TextField, useMediaQuery } from '@mui/material';
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { useErrorBoundary } from 'react-error-boundary';

import { dateTimeGetter, dateTimeFormatter } from '../../mui_utils';
import { FetchSectors, FetchContracts, ApplicationError } from '../../../services/fetch'
import CustomToolbar from './customtoolbar'

const roleMapping = {
  'Employé': 'employee',
  'Contremaître': 'foreman',
  'Administrateur': 'admin'
};

const reverseRoleMapping = {
  'employee': 'Employé',
  'foreman': 'Contremaître',
  'admin': 'Administrateur'
};

function addIdToDicts(listOfDicts) {
  for (const dict of listOfDicts) {
    dict.temp_role = reverseRoleMapping[dict.role]
  }
}

export default function UserList({users, onActiveChange, onUserUpdate, onUserCreation, onUserDeletion}) {
  if (users) {
    addIdToDicts(users);
  }

  const [rows, setRows] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [contracts, setContracts] = useState([]);
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    setRows(users);
    setUpdating(false);
  }, [users]);

  useEffect(() => {
    FetchSectors(false)
      .then((sectors) => setSectors(sectors))
      .then(() => FetchContracts(false))
      .then((contracts) => setContracts(contracts))
      .catch((ex) => showBoundary(ex));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.up("xs"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));

  const defaultUserRenderCell = (params) => (
  <Box>
    {params.row.name}
  </Box>
  );

  const xsUserRenderCell = (params) => (
    <Box>
      <Box>{params.row.name}</Box>
      <Box sx={{ fontSize: 12 }}>{params.row.number}</Box>
    </Box>
  );
  
  const userRenderCell = lg ? defaultUserRenderCell : xsUserRenderCell;

  let columnVisibility;
  let leftPadding = 0;
  let rightPadding = 0;
  let raidusBorder = 0;

  if (xl) {
    columnVisibility = {
    };

    leftPadding = 25
    rightPadding = 25
    raidusBorder = 0

  } else if (lg) {
    columnVisibility = {
      created_at: false
    };

  } else if (md) {
    columnVisibility = {
      created_at: false,
      number: false,
      position: false,
      is_active: false
    };

  } else if (sm) {
    columnVisibility = {
      created_at: false,
      number: false,
      position: false,
      is_active: false
    };

  } else if (xs) {
    columnVisibility = {
      created_at: false,
      number: false,
      position: false,
      sector: false,
      is_active: false
    };

  }

  let columns = [
    {
      field: 'name',
      headerName: 'Nom',
      renderHeader: () => (
        <strong>
          {'Nom'}
        </strong>
      ),
      type: 'string',
      flex: 0.8,
      renderCell: userRenderCell
    },
    {
      field: 'number',
      headerName: "Numéro d'employé",
      renderHeader: () => (
        <strong>
          {"# Employé"}
        </strong>
      ),
      type: 'String',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        if (params.row.number === null) {
          return (
            <Box>
              <Box>--</Box>
            </Box>
          )
        } else {
          return (
            <Chip
              label={params.row.number}
              size="small"
              style={{ marginTop: '5px' }}
            />
          )
        }
      }
    },
    {
      field: 'position',
      headerName: "Poste",
      renderHeader: () => (
        <strong>
          {'Poste'}
        </strong>
      ),
      type: 'String',
      editable: !updating,
      flex: 1
    },
    {
      field: 'sector',
      headerName: "Secteur",
      renderHeader: () => (
        <strong>
          {'Secteur'}
        </strong>
      ),
      type: 'singleSelect',
      editable: !updating,
      valueOptions: sectors.map((sector) => sector.name),
      flex: 1,
    },
    {
      field: 'temp_role',
      headerName: "Role",
      renderHeader: () => (
        <strong>
          {'Rôle'}
        </strong>
      ),
      type: 'singleSelect',
      editable: !updating,
      valueOptions: [
        'Employé',
        'Contremaître',
        'Administrateur'
      ],
      flex: 1
    },
    {
      field: 'default_contract_id',
      renderHeader: () => (
        <strong>
          {'Contrat par défaut'}
        </strong>
      ),
      flex: 1,
      editable: !updating,
      type: 'integer',
      hideable: false,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        contracts?.find((contract) => contract.id === params.value)?.label || ''
      ),
      renderEditCell: (params) => (
        <Autocomplete
          options={contracts}
          style={{ width: '100%', minWidth: '300px' }}
          value={contracts?.find((contract) => contract.id === params.value)}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
            />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, newValue) => {
            params.api.setEditCellValue({
              id: params.id,
              field: 'default_contract_id',
              value: newValue?.id,
            });
          }}
        />
      )
    },
    {
      field: 'is_active',
      headerName: "Actif",
      renderHeader: () => (
        <strong>
          {'Actif'}
        </strong>
      ),
      type: 'boolean',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      renderCell: (params) => {
        const handleSwitchChange = async (event, checked) => {
          try {
            if (updating) {
              return false;
            }
            event.target.disabled = true;
            setUpdating(true);
            const user_id = params.row.id;
            const payload = {
              ...params.row,
              is_active: checked,
            };
            await onActiveChange(user_id, payload);
            return false;
          } catch(error) {
            event.target.disabled = false;
            setUpdating(false);
            return false;
          }
        };

        return (
          <Box>
            <Switch
              checked={params.row.is_active}
              disabled={updating}
              onChange={handleSwitchChange}
            />
          </Box>
        )
      }
    },
    {
      field: 'created_at',
      headerName: "Créé le",
      renderHeader: () => (
        <strong>
          {'Créé le'}
        </strong>
      ),
      type: 'dateTime',
      valueGetter: dateTimeGetter,
      valueFormatter: dateTimeFormatter,
      flex: 1
    },
    {
      field: 'action',
      type: 'actions',
      flex: 1,
      renderCell: (params) => {
        const user_id = params.row.id;
        
        const handleClick = async () => {
          setUpdating(true);
          await onUserDeletion(user_id);
        };
  
        return (
          <IconButton
            size="large"
            color="inherit"
            onClick={handleClick}
          >
            <DeleteIcon style={{ color: '#343434' }}/>
          </IconButton>
        );
      }
    }
  ]

  return (
    <div style={{ backgroundColor: '#f0f0f0', minHeight: '100vh', paddingTop: '0.1px' }}>
      <Box sx={{ backgroundColor: 'white' }}>
        <Box sx={{ backgroundColor: '#f0f0f0', paddingTop: 0, paddingBottom: 5, paddingLeft: leftPadding, paddingRight: rightPadding }}>
          <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: raidusBorder }}>
            {!rows ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
              </Box>
            ) : (
              <DataGridPro
                getRowId={(row) => row.id}
                editMode="row"
                pagination
                paginationPageSize={100}
                disableColumnSelector
                disableDensitySelector
                columns={columns}
                columnVisibilityModel={columnVisibility}
                rows={rows}
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                  toolbar: {
                    onUserCreation: onUserCreation
                  },
                }}
                onProcessRowUpdateError={(error) => {
                  if (ApplicationError.is(error, 'invalid_data')) {
                    alert("Les données saisies sont invalides.");
                  } else {
                    console.error(error);
                    alert("Une erreur s'est produite lors de la sauvegarde des données.");
                  }
                  setUpdating(false);
                }}
                processRowUpdate={async (updatedRow, originalRow) => {
                  if (!updatedRow.position ||
                      !updatedRow.sector ||
                      !updatedRow.temp_role) {
                    throw new ApplicationError({error: 'invalid_data'});
                  };
                  setUpdating(true);
                  const user_id = updatedRow.id;
                  const payload = {
                    ...updatedRow,
                    "role": roleMapping[updatedRow.temp_role],
                  };
                  const newUser = await onUserUpdate(user_id, payload);
                  return newUser;
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
}