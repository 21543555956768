//import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { responsiveFontSizes } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { frFR } from '@mui/material/locale';
import { frFR as frFRGrid } from '@mui/x-data-grid/locales';
import { frFR as frFRDatePickers } from '@mui/x-date-pickers/locales';
import { LicenseInfo } from '@mui/x-license-pro';
import { ErrorBoundary } from 'react-error-boundary';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { grey } from '@mui/material/colors';

import './index.css';
import App from './App';
import { logout } from './components/use/login';
//import reportWebVitals from './reportWebVitals';

const MUI_LICENSE_KEY = process.env.REACT_APP_MUI_LICENSE_KEY

if (MUI_LICENSE_KEY) {
  LicenseInfo.setLicenseKey(MUI_LICENSE_KEY)
}

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.locale('fr');

const resetApplication = () => {
  try {
    logout();
  } catch {
    // Do nothing
  }

  window.location.reload();
}

function Fallback({ error, resetErrorBoundary }) {

  return (
    <div role="alert">
      <p>Désolé, une erreur s'est produite&nbsp;:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
      { /* eslint-disable jsx-a11y/anchor-is-valid */ }
      <p>
        {'Vous pouvez '}
        <a href="#" onClick={resetErrorBoundary}>ré-essayer</a>
        {' ou '}
        <a href="#" onClick={resetApplication}>réinitialiser l'application</a>
        .
      </p>
    </div>
  );
}

const logError = (error, info) => {
  // TODO: Send errors to the Cloud
  console.error(error);
};

const theme = responsiveFontSizes(createTheme({
  components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            variants: [
              {
                props: { variant: 'contained' },
                style: {
                  backgroundColor: grey[700], 
                  '&:hover': {
                    backgroundColor: grey[600]
                  }
                },
              },
            ],
          },
        },
      },
    },
  },
  frFR,
  frFRGrid,
  frFRDatePickers
));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // Strict mode has been commented out because it makes fetching data occurs twice
  //<StrictMode>
    <ErrorBoundary FallbackComponent={Fallback} onError={logError}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
          <App />
        </LocalizationProvider>
      </ThemeProvider>
    </ErrorBoundary>
  //</StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
