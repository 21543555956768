import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Fragment } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';

import LeftMenu from './leftmenu';
import whitelogo from '../../assets/cppi-logo-white.png';
import { logout } from '../use/login';

export default function LeftPanel({role, username}) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();

    navigate('/');
  }

  return (<div>
    <IconButton
      size="large"
      edge="start"
      color="inherit"
      aria-label="open drawer"
      onClick={() => setOpen(true)}
      sx={{ mr: 2 }}
    >
      <MenuIcon />
    </IconButton>
    <Fragment key='left'>
      <Drawer
        anchor='left'
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box sx={{ backgroundColor: '#343434' }}>
          <Box sx={{ marginTop: '15px', marginLeft: '35px', marginRight: '20px', alignContent: 'center' }}>
            <img src={whitelogo} alt="logo" width="175" />
          </Box>
        </Box>
        <LeftMenu role={role} setOpen={setOpen} />
        <div style={{ flex: 1 }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', height: '100%', backgroundColor: '#343434' }}>
          
          <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
            <Box sx={{ padding: 1 }}>
              <Avatar sx={{ bgcolor: deepOrange[500], borderRadius: '4px' }} variant="square">
                <Typography sx={{ fontFamily: 'Arial, sans-serif' }}>
                  {username[0]}
                </Typography>
              </Avatar>
            </Box>
            <Box>
              <Typography sx={{ color: '#E0E0E0', fontFamily: 'Arial, sans-serif' }}>
                {username}
              </Typography>
            </Box>
          </Box>
          </Box>
          <Box sx={{ padding: 2 }}>
            <Box>
              <Button sx={{ color: '#E0E0E0' }} onClick={handleLogout} startIcon={<LogoutIcon />}>
                <Typography sx={{ color: '#E0E0E0', fontFamily: 'Arial, sans-serif' }}>
                  Se déconnecter
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </Fragment>
  </div>);
};
