import Box from '@mui/material/Box';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import Switch from '@mui/material/Switch';
import { ContractsToolbar } from './contracts_toolbar';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { dateTimeGetter, dateTimeFormatter } from '../mui_utils';
import { ApplicationError } from '../../services/fetch';

export default function ContractList({contracts, handleContractUpdate, handleContractCreation, handleContractDeletion}) {
  const [rows, setRows] = useState([]);
  const [updating, setUpdating] = useState(false);
  
  useEffect(() => {
    setRows(contracts);
    setUpdating(false);
  }, [contracts]);

  let columns = [
    {
        field: 'value',
        headerName: 'Numéro',
        renderHeader: () => (
            <strong>
              {'Numéro'}
            </strong>
        ),
        type: 'string',
        flex: 0.6,
        editable: !updating
    },
    {
      field: 'label',
      headerName: 'Description',
      renderHeader: () => (
          <strong>
            {'Description'}
          </strong>
      ),
      type: 'string',
      flex: 2,
      editable: !updating
    },
    {
      field: 'is_active',
      headerName: 'Actif',
      renderHeader: () => (
          <strong>
            {'Actif'}
          </strong>
      ),
      type: 'boolean',
      headerAlign: 'left',
      align: 'left',
      flex: 0.4,
      renderCell: (params) => {
        const handleSwitchChange = async (event, checked) => {
          try {
            if (!updating) {
              event.target.disabled = true;
              setUpdating(true);
              const contract_id = params.row.id
              const payload = {
                ...params.row, 
                is_active: checked,
              };
              await handleContractUpdate(contract_id, payload);
            }
          } catch(error) {
            event.target.disabled = false;
            setUpdating(false);
          }
        };

        return (
            <Box>
                <Switch
                    checked={params.row.is_active}
                    onChange={handleSwitchChange}
                    disabled={updating}
                />
            </Box>
        )
      }
    },
    {
      field: 'created_at',
      headerName: "Créé le",
      renderHeader: () => (
        <strong>
          {'Créé le'}
        </strong>
      ),
      type: 'dateTime',
      valueGetter: dateTimeGetter,
      valueFormatter: dateTimeFormatter,
      flex: 0.6
    },
    {
      field: 'action',
      type: 'actions',
      flex: 0.4,
      renderCell: (params) => {
        const contract_id = params.row.id;

        const handleClick = async () => {
          setUpdating(true);
          await handleContractDeletion(contract_id);
        };
            
        return (
          <IconButton
            size="large"
            color="inherit"
            onClick={handleClick}
          >
            <DeleteIcon style={{ color: '#343434' }}/>
          </IconButton>
        );
      }
    }
  ]

  const theme = useTheme();
  //const xs = useMediaQuery(theme.breakpoints.up("xs"));
  //const sm = useMediaQuery(theme.breakpoints.up("sm"));
  //const md = useMediaQuery(theme.breakpoints.up("md"));
  //const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));


  //let columnVisibility;
  let leftPadding = 0;
  let rightPadding = 0;
  let raidusBorder = 0;

  if (xl) {

    leftPadding = 25
    rightPadding = 25
    raidusBorder = 0

  };


  return (
    <div style={{ backgroundColor: '#f0f0f0', minHeight: '100vh', paddingTop: '0.1px' }}>
      <Box sx={{ backgroundColor: 'white' }}>
        <Box sx={{ backgroundColor: '#f0f0f0', paddingTop: 0, paddingBottom: 5, paddingLeft: leftPadding, paddingRight: rightPadding }}>
        <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: raidusBorder }}>
          <DataGridPro
            getRowId={(row) => row.id}
            editMode="row"
            pagination
            paginationPageSize={100}
            disableColumnSelector
            disableDensitySelector
            columns={columns}
            rows={rows}
            rowHeight={38}
            slots={{ toolbar: ContractsToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                handleContractCreation: handleContractCreation
              },
            }}
            disableRowSelectionOnClick
            onProcessRowUpdateError={(error) => {
              if (ApplicationError.is(error, 'invalid_data')) {
                alert("Les données saisies sont invalides.");
              } else {
                console.error(error);
                alert("Une erreur s'est produite lors de la sauvegarde des données.");
              }
              setUpdating(false);
            }}
            processRowUpdate={async (updatedRow, originalRow) => {
              if (!updatedRow.value ||
                  !updatedRow.label
              ) {
                throw new ApplicationError({error: 'invalid_data'});
              };
              setUpdating(true);
              const contract_id = updatedRow.id;
              const payload = {
                ...updatedRow,
              };
              const newContract = await handleContractUpdate(contract_id, payload);
              return newContract;
            }}
          />
          </Box>
        </Box>
      </Box>
    </div>
  );
}